<template>
</template>
<script>
    import { mapActions } from 'vuex';
    import axios from 'axios';
    import SetInterval from "set-interval";
    import moment from 'moment'; // Importa Moment.js
    import wCodes from "../wCodes";


    var config = require("@/config/api.json");
    const API = config.api;
    export default {
        name: 'SoilData',
        data() {
            return {
                user: null,
                farms: [],
                active: false,
                options: [],
                observations: [],
                sampleTime: 1200000,
                sensors: [],
                lightning: []

            }
        },
        props: {
            reset: Boolean,
            itemData: Object
        },
        async mounted() {
            console.log('Mounted main timer......');
            setTimeout(async () => {
                const storedUser = localStorage.getItem('user');
                if (storedUser) {
                    console.log(this.itemData)
                    this.user = JSON.parse(storedUser);
                    await this.getFarms();
                    await this.getSensors();
                    this.verifySoil();
                    this.verifyDavis();
                    this.verifyCampbell();
                    this.verifyInternet();
                    this.verifyManual();
                    // console.log('Main Item', this.itemData)
                    // console.log(this.farms)
                    // if (this.itemData.sampleTime || parseInt(this.itemData.sampleTime) > 0) {
                    //     this.sampleTime = parseInt(this.itemData.sampleTime * 60000);
                    // }
                }
            }, 1000);
        },

        methods: {


            calculateTierDaysRemaining(endDate) {
                const today = moment();
                const end = moment(endDate);
                return end.diff(today, 'days');
            },


            async getSensors() {
                await axios
                    .get(`${API}/api-sensors`)
                    .then((response) => {
                        this.sensors = response.data;
                    });
            },

            async getWeatherDataSoil(serial, path, id, name) {
                console.log('Running main timer...')
                await axios
                    .get(`${API}/getScoutData/${serial}/${id}`)
                    .then((data) => {
                        if (data.data == "token_not_valid") {
                            this.notify('success', `Scout data taking longer than expected`);
                        } else {
                            let soilData = data.data[0].last_measurement;

                            let us = this.user
                            // ipcRenderer.send("createSoilFile", {
                            //     soilData,
                            //     us,
                            //     path,
                            //     id,
                            //     serial,
                            //     name
                            // });
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        this.notify('success', `Error reading Soil: no data received`);
                    });
            },



            async turnSoil(id, value, name, serial, path) {
                try {
                    const response = await axios.post(`${API}/turnSoil`, {
                        id: id
                    });
                    if (parseInt(value) == 0) {
                        SetInterval.clear(`soilScout${id}`);
                        this.notify('success', `Stopped ${name}`);
                    } else {
                        SetInterval.clear(`soilScout${id}`);
                        this.getWeatherDataSoil(serial, path, id, name);
                        SetInterval.start(() => { this.getWeatherDataSoil(serial, path, id, name); }, this.sampleTime, `soilScout${id}`);
                        this.notify('success', `Started ${name}`);
                    }
                    this.getFarms();

                } catch (error) {
                    console.error('Error', error);
                    this.notify('error', 'Failed to fetch farms');
                }
            },

            async getFarms() {
                this.farms = [];
                try {
                    const response = await axios.get(`${API}/getFarmsByOwner/${this.user.id}`);
                    this.farms = response.data;
                } catch (error) {
                    console.error('Error', error);
                    this.notify('error', 'Failed to fetch farms');
                }
            },
            async verifySoil() {
                if (this.farms && this.farms.length > 0) {
                    this.farms.forEach(farm => {
                        if (this.calculateTierDaysRemaining(farm.endDate) > 0) {
                            this.sampleTime = parseInt(farm.sampleTime * 60000);
                            if (farm.stations && farm.stations.length > 0) {
                                const soilStations = farm.stations.filter(station => station.sensor_type === 3);
                                if (soilStations.length > 0) {
                                    soilStations.forEach(station => {
                                        if (station.sensors && station.sensors.length > 0) {
                                            const activeSensors = station.sensors.filter(sensor => sensor.last_status === 1);
                                            if (activeSensors.length > 0) {
                                                activeSensors.forEach(element => {
                                                    SetInterval.start(() => {
                                                        this.getWeatherDataSoil(element.config, farm.storage_path, element.id, station.name);
                                                    }, this.sampleTime, `soilScout${element.id}`);
                                                    this.getWeatherDataSoil(element.config, farm.storage_path, element.id, station.name)
                                                });
                                            }
                                        }
                                    });
                                }
                            }
                        }
                    });
                }
            },
            async verifyDavis() {
                if (this.farms && this.farms.length > 0) {
                    this.farms.forEach(farm => {
                        console.log("FARM TIMER!!!!!!!!!!!!!", farm)
                        if (this.calculateTierDaysRemaining(farm.endDate) > 0) {
                            this.sampleTime = parseInt(farm.sampleTime * 60000);
                            if (farm.stations && farm.stations.length > 0) {
                                const davisStations = farm.stations.filter(station => station.sensor_type === 1);
                                if (davisStations.length > 0) {
                                    davisStations.forEach(station => {
                                        if (station.sensors && station.sensors.length > 0) {
                                            const activeSensors = station.sensors.filter(sensor => sensor.last_status === 1);
                                            if (activeSensors.length > 0) {
                                                activeSensors.forEach(element => {
                                                    console.log('INICIANDO DAVIS::::::::::::::::::::', this.sampleTime, element)
                                                    SetInterval.start(() => {
                                                        this.getWeatherDataDavis(element.config, davisStations[0].config, element.id, element.name, farm.storage_path);
                                                    }, this.sampleTime, `davis${element.id}`);
                                                    // this.getWeatherDataDavis(element.config, davisStations[0].config, element.id, element.name, farm.storage_path)
                                                });
                                            }
                                        }
                                    });
                                }
                            }
                        }
                    });
                }
            },

            async verifyCampbell() {
                if (this.farms && this.farms.length > 0) {
                    this.farms.forEach(farm => {
                        if (this.calculateTierDaysRemaining(farm.endDate) > 0) {
                            this.sampleTime = parseInt(farm.sampleTime * 60000);
                            if (farm.stations && farm.stations.length > 0) {
                                const campbellStations = farm.stations.filter(station => station.sensor_type === 2);
                                if (campbellStations.length > 0) {
                                    campbellStations.forEach(station => {
                                        if (station.sensors && station.sensors.length > 0) {
                                            const activeSensors = station.sensors.filter(sensor => sensor.last_status === 1);
                                            if (activeSensors.length > 0) {
                                                activeSensors.forEach(element => {
                                                    console.log('INICIANDO CAMPBELLLLLLLL::::::::::::::::::::', this.sampleTime, element)
                                                    SetInterval.start(() => {
                                                        // (stationId, config, sensorId, name, path)
                                                        this.getDataCampbell(station.id, station.config, element.id, element.name, farm.storage_path);
                                                    }, this.sampleTime, `campbell${element.id}`);
                                                });
                                            }
                                        }
                                    });
                                }
                            }
                        }
                    });
                }
            },
            async verifyInternet() {
                if (this.farms && this.farms.length > 0) {
                    this.farms.forEach(farm => {
                        if (this.calculateTierDaysRemaining(farm.endDate) > 0) {
                            this.sampleTime = parseInt(farm.sampleTime * 60000);
                            if (farm.stations && farm.stations.length > 0) {
                                const internetStations = farm.stations.filter(station => station.sensor_type === 4);
                                if (internetStations.length > 0) {
                                    internetStations.forEach(station => {
                                        if (station.sensors && station.sensors.length > 0) {
                                            const activeSensors = station.sensors.filter(sensor => sensor.last_status === 1);
                                            if (activeSensors.length > 0) {
                                                activeSensors.forEach(element => {
                                                    console.log('INICIANDO INTERNET!!!!!!!!!!!!!!!!!!!!!!!', this.sampleTime, element)
                                                    SetInterval.start(() => {
                                                        // (stationId, config, sensorId, name, path)
                                                        this.getDataInternet(station.id, station.config, element.id, element.name, farm.storage_path, element.config);
                                                    }, this.sampleTime, `internet${element.id}`);
                                                });
                                            }
                                        }
                                    });
                                }
                            }
                        }
                    });
                }
            },


            async verifyManual() {
                if (this.farms && this.farms.length > 0) {
                    this.farms.forEach(farm => {
                        if (this.calculateTierDaysRemaining(farm.endDate) > 0) {
                            this.sampleTime = parseInt(farm.sampleTime * 60000);
                            if (farm.stations && farm.stations.length > 0) {
                                const internetStations = farm.stations.filter(station => station.sensor_type === 5);
                                if (internetStations.length > 0) {
                                    internetStations.forEach(station => {
                                        if (station.sensors && station.sensors.length > 0) {
                                            const activeSensors = station.sensors.filter(sensor => sensor.last_status === 1);
                                            if (activeSensors.length > 0) {
                                                activeSensors.forEach(element => {
                                                    console.log('INICIANDO MANUAL!!!!!!!!!!!!!!!!!!!!!!!', this.sampleTime, element)
                                                    SetInterval.start(() => {
                                                        // (stationId, config, sensorId, name, path)
                                                        this.getDataManual(station.id, station.config, element.id, element.name, farm.storage_path, element.config);
                                                    }, this.sampleTime, `manual${element.id}`);
                                                });
                                            }
                                        }
                                    });
                                }
                            }
                        }
                    });
                }
            },

            async getDataInternet(stationId, config, sensorId, name, path, sensor_config) {
                var vm = this;
                console.log('Internet running.....', stationId, sensorId)
                let internetConfig = JSON.parse(config);

                let us = this.user;

                let obj = {
                    stationId: stationId,
                    config: config,
                    sensorId: sensorId,
                    name: name,
                    path: path,
                    sensor_config: sensor_config,
                    zone: Intl.DateTimeFormat().resolvedOptions().timeZone
                }

                await axios
                    .post(`${API}/getInternetData`, {
                        obj
                    })
                    .then((data) => {

                        console.log('INTERNET DATA')
                        console.log(data.data)

                        let response = data.data;
                        var sortedObjs = _.sortBy(response, "id");

                        let obj = [];
                        vm.lightning = [];
                        let lightId = 0;

                        sortedObjs.forEach(function (elementx, indexx) {

                            vm.lightning.push({
                                id: elementx.id,
                                stationId: stationId,
                                value: "0",
                                path: path
                            });
                            let interVals = elementx.intervals;
                            interVals.forEach(function (element2, index2) {
                                console.log('interVals');
                                console.log(element2)
                                let precipitationProbability =
                                    element2.values.precipitationProbability;
                                let precipitationIntensity =
                                    element2.values.precipitationIntensity;
                                let precipitationType = element2.values.precipitationType;
                                let weatherCode = element2.values.weatherCode;
                                let windSpeed = element2.values.windSpeed;


                                let wCodesSearch = wCodes.weatherCodes;
                                let src, weatherCodeDesc;
                                wCodesSearch.find((code) => {
                                    if (code.id == weatherCode) {
                                        src = require("@/assets/" + code.src);
                                        weatherCodeDesc = code.desc;
                                        vm.lightning[indexx]["icon"] = src;
                                        vm.lightning[indexx]["weatherDesc"] = weatherCodeDesc;
                                    }
                                });

                                vm.lightning[indexx]["observation_time"] = element2.startTime;
                                vm.lightning[indexx]["weatherValues"] = element2.values;


                                //Light Rain
                                if (
                                    parseInt(weatherCode) == 4200 &&
                                    parseFloat(precipitationProbability) >= 60 &&
                                    parseFloat(windSpeed) >= 10 &&
                                    parseFloat(precipitationIntensity >= 0.5)
                                ) {
                                    vm.lightning[indexx].value = "1";
                                    vm.lightning[indexx]["path"] = path;
                                }

                                //Rain
                                if (
                                    parseInt(weatherCode) == 4001 &&
                                    parseFloat(precipitationProbability) >= 50 &&
                                    parseFloat(windSpeed) >= 9 &&
                                    parseFloat(precipitationIntensity >= 0.25)
                                ) {
                                    vm.lightning[indexx].value = "1";
                                    vm.lightning[indexx]["path"] = path;
                                }

                                //Heavy Rain
                                if (
                                    parseInt(weatherCode) == 4201 &&
                                    parseFloat(precipitationProbability) >= 50
                                ) {
                                    vm.lightning[indexx].value = "1";
                                    vm.lightning[indexx]["path"] = path;
                                }
                                //ThunderStorm
                                if (parseInt(weatherCode) == 8000) {
                                    vm.lightning[indexx].value = "1";
                                    vm.lightning[indexx]["path"] = path;
                                }

                            });

                            // Verificar si es el último elemento
                            if (indexx === sortedObjs.length - 1) {
                                console.log("Este es el último elemento del arreglo.");
                                console.log(vm.lightning)
                                // ipcRenderer.send("createInternetFile", vm.lightning);
                            }
                        });

                    })
                    .catch(function (error) {
                        console.log('catch!!')
                        console.log(error)
                        // vm.displayError(`Error ${error}`);
                        return;
                    });
            },
            async getWeatherDataDavis(stationId, config, sensorId, name, path) {
                console.log('WEATHER DATA:::::', stationId, config, sensorId, name, path)
                var vm = this;
                console.log('Weather Davis', stationId, sensorId)
                let davisConnection = JSON.parse(config);
                this.observations = [];
                var weekly_rain = 0;
                var monthly_rain = 0;
                var yearly_rain = 0;
                var hasRemotes = false;



                await axios
                    .post(`${API}/getDavisData`, {
                        apiKey: davisConnection.apiKey,
                        apiSecret: davisConnection.apiSecret,
                        stationId: stationId,
                    })
                    .then((response) => {
                        console.log("DATA WEATER LINK.............");
                        console.log(response);
                        vm.observations = response.data.sensors[0].data[0];

                        let mainData = response.data.sensors;

                        let totalData = response.data.sensors.length;
                        if (totalData > 1) {
                            mainData.forEach(function (element) {
                                hasRemotes = true;
                                if (vm.observations["etPast"] === undefined) {
                                    vm.observations["etPast"] = 0;
                                }
                                if (element.etPast) {
                                    vm.observations["etPast"] = element.etPast;
                                }


                                if (element.data_structure_type == 6 || element.data_structure_type == 2) {
                                    console.log("---------------------SENSOR 2 and 6---------------------");
                                    vm.observations["bar"] = element.data[0]["bar"];
                                    vm.observations["hum_out"] = element.data[0]["hum_out"];
                                    vm.observations["temp_out"] = element.data[0]["temp_out"];
                                    vm.observations["wind_dir"] = element.data[0]["wind_dir"];
                                    vm.observations["wind_speed"] = element.data[0]["wind_speed"];
                                    vm.observations["et_day"] = element.data[0]["et_day"];
                                    vm.observations["rain_day_in"] = element.data[0]["rain_day_in"];

                                    vm.observations["weekly_rain"] = weekly_rain;
                                    vm.observations["monthly_rain"] = monthly_rain;
                                    vm.observations["yearly_rain"] = yearly_rain;

                                    if (element.data[0]["rain_day_in"] !== undefined) {
                                        console.log("RAIN OK");
                                        vm.storeRain(vm.user, stationId, element.data[0]["rain_day_in"]);
                                    }

                                    vm.observations["solar_rad"] = element.data[0]["solar_rad"];
                                    if (!element.data[0]["uv"]) {
                                        vm.observations["uv"] = 0.0;
                                    }
                                    if (vm.observations["Normally_Closed"] > 0) {
                                        vm.observations["Normally_Closed"] = 1;
                                    } else {
                                        vm.observations["Normally_Closed"] = 0;
                                    }
                                } else {
                                    console.log('entra ELSE...')
                                    console.log(vm.sensors)
                                    vm.sensors.forEach((sensor) => {
                                        console.log(sensor)
                                        if (sensor.id == element.sensor_type) {
                                            var str = sensor.name;
                                            var sensor_fixed = str.split(" ").join("_");
                                            vm.observations[sensor_fixed] = element.data[0][sensor.field];
                                            if (vm.observations["Soil_Moisture_SM150"]) {
                                                vm.observations["Soil_Moisture_SM150"] = 0.0;
                                            }
                                            if (!vm.observations["Normally_Closed"] > 0) {
                                                vm.observations["Normally_Closed"] = 1;
                                            } else {
                                                vm.observations["Normally_Closed"] = 0;
                                            }

                                            if (!vm.observations.sensors) {
                                                vm.observations.sensors = [];
                                            }

                                            vm.observations.sensors.push({
                                                name: sensor_fixed,
                                                value: element.data[0][sensor.field]
                                            });
                                        }
                                    });
                                }
                            });
                        } else {
                            vm.observations["weekly_rain"] = weekly_rain;
                            vm.observations["monthly_rain"] = monthly_rain;
                            vm.observations["yearly_rain"] = yearly_rain;
                            // vm.storeRain(vm.user, api, vm.observations.rain_day_in);
                            hasRemotes = false;
                        }

                        if (!vm.observations["bar"]) {
                            vm.observations["bar"] = 0.0;
                        }
                        if (!vm.observations["hum_out"]) {
                            vm.observations["hum_out"] = 0.0;
                        }
                        if (!vm.observations["temp_out"]) {
                            vm.observations["temp_out"] = 0.0;
                        }
                        if (!vm.observations["wind_dir"]) {
                            vm.observations["wind_dir"] = 0.0;
                        }
                        if (!vm.observations["wind_speed"]) {
                            vm.observations["wind_speed"] = 0.0;
                        }
                        if (!vm.observations["et_day"]) {
                            vm.observations["et_day"] = 0.0;
                        }
                        if (!vm.observations["rain_day_in"]) {
                            vm.observations["rain_day_in"] = 0.0;
                        }
                        if (!vm.observations["solar_rad"]) {
                            vm.observations["solar_rad"] = 0.0;
                        }
                        if (!vm.observations["uv"]) {
                            vm.observations["uv"] = 0.0;
                        }
                        if (!vm.observations["temperature_probe"]) {
                            vm.observations["temperature_probe"] = 0.0;
                        }
                        if (!vm.observations["etPast"]) {
                            vm.observations["etPast"] = 0.0;
                        }
                        if (vm.observations["Soil_Moisture_SM150"]) {
                            vm.observations["Soil_Moisture_SM150"] = 0.0;
                        }
                        vm.observations["sensorId"] = sensorId;
                        vm.observations["sensorId"] = sensorId;
                        vm.observations["user"] = vm.user;
                        vm.observations["path"] = path;
                        vm.observations["observation_time"] = moment
                            .unix(response.data.generated_at)
                            .format("lll");
                    })
                    .then(() => {
                        let davis = vm.observations;
                        let iccDb = [];
                        iccDb["sensor_id"] = sensorId;
                        iccDb["stationId"] = stationId;
                        iccDb["observation_time"] = vm.observations["observation_time"];
                        iccDb["bar"] = vm.observations["bar"];
                        iccDb["hum_out"] = vm.observations["hum_out"];
                        iccDb["temp_out"] = vm.observations["temp_out"];
                        iccDb["wind_dir"] = vm.observations["wind_dir"];
                        iccDb["wind_speed"] = vm.observations["wind_speed"];
                        iccDb["et_day"] = vm.observations["et_day"];
                        iccDb["rain_day_in"] = vm.observations["rain_day_in"];
                        iccDb["solar_rad"] = vm.observations["solar_rad"];
                        iccDb["uv"] = vm.observations["uv"];
                        iccDb["temperature_probe"] = vm.observations["temperature_probe"];

                        let mSensors = [];
                        mSensors = vm.observations.sensors;
                        if (mSensors) {
                            if (!mSensors[0]) {
                                iccDb["m1501"] = 0;
                            } else {
                                iccDb["m1501"] = mSensors[0].value;
                            }
                            if (!mSensors[1]) {
                                iccDb["m1502"] = 0;
                            } else {
                                iccDb["m1502"] = mSensors[1].value;
                            }
                            if (!mSensors[2]) {
                                iccDb["m1503"] = 0;
                            } else {
                                iccDb["m1503"] = mSensors[2].value;
                            }
                            if (!mSensors[3]) {
                                iccDb["m1504"] = 0;
                            } else {
                                iccDb["m1504"] = mSensors[3].value;
                            }
                            if (!mSensors[4]) {
                                iccDb["m1505"] = 0;
                            } else {
                                iccDb["m1505"] = mSensors[4].value;
                            }
                            if (!mSensors[5]) {
                                iccDb["m1506"] = 0;
                            } else {
                                iccDb["m1506"] = mSensors[5].value;
                            }
                            if (!mSensors[6]) {
                                iccDb["m1507"] = 0;
                            } else {
                                iccDb["m1507"] = mSensors[6].value;
                            }
                            if (!mSensors[7]) {
                                iccDb["m1508"] = 0;
                            } else {
                                iccDb["m1508"] = mSensors[7].value;
                            }
                            if (!mSensors[8]) {
                                iccDb["m1509"] = 0;
                            } else {
                                iccDb["m1509"] = mSensors[8].value;
                            }
                            if (!mSensors[9]) {
                                iccDb["m15010"] = 0;
                            } else {
                                iccDb["m15010"] = mSensors[9].value;
                            }
                        } else {
                            iccDb["m1501"] = '0';
                            iccDb["m1502"] = '0';
                            iccDb["m1503"] = '0';
                            iccDb["m1504"] = '0';
                            iccDb["m1505"] = '0';
                            iccDb["m1506"] = '0';
                            iccDb["m1507"] = '0';
                            iccDb["m1508"] = '0';
                            iccDb["m1509"] = '0';
                            iccDb["m15010"] = '0';
                        }

                        if (!vm.observations["Soil_Moisture_SM150"]) {
                            iccDb["soil_moisture_sm150"] = 0;

                        } else {
                            iccDb["soil_moisture_sm150"] = vm.observations["soil_moisture_sm150"];
                        }
                        if (!vm.observations["Soil_Moisture_SM150T"]) {
                            iccDb["soil_Moisture_SM150T"] = 0;

                        } else {
                            iccDb["soil_Moisture_SM150T"] = vm.observations["Soil_Moisture_SM150T"];
                        }
                        iccDb["normally_closed"] = vm.observations["Normally_Closed"];
                        iccDb["etPast"] = vm.observations["etPast"];
                        iccDb["weekly_rain"] = vm.observations["weekly_rain"];
                        iccDb["monthly_rain"] = vm.observations["monthly_rain"];
                        iccDb["yearly_rain"] = vm.observations["yearly_rain"];
                        iccDb["path"] = path;
                        iccDb["sensorId"] = sensorId;
                        iccDb["sensorName"] = name;
                        console.log("ICC", iccDb);

                        // ipcRenderer.send("createDavisFile", iccDb);
                    })
                    .catch(function (error) {
                        console.log('catch!!')
                        console.log(error)
                        return;
                    });
            },

            async getDataCampbell(stationId, config, sensorId, name, path) {
                var vm = this;
                console.log('Campbell running.....', stationId, sensorId)
                let campbellConfig = JSON.parse(config);


                console.log('CONFIG', campbellConfig)
                await axios
                    .post(`${API}/getCampbellData`, {
                        api: campbellConfig,
                    })
                    .then((response) => {
                        if (response.data == "error-nested-modem") {
                            console.log("SMS 1 ----------------------ERROR NESTED ");
                            this.notify("error", "Error on campbell router. Please click on 'config: url' to verify modem connection");
                        } else {
                            let responseValues = response.data.data[0].vals;
                            let responseNames = response.data.head.fields;
                            var newObj = [];
                            for (var key = 0; key < responseValues.length; key++) {
                                newObj.push({
                                    value: responseValues[key],
                                    name: responseNames[key].name,
                                });
                            }
                            console.log(newObj)
                        }

                        let campbellData = newObj

                        let us = this.user;
                        // ipcRenderer.send("createCampbellFile", {
                        //     campbellData,
                        //     us,
                        //     path,
                        //     stationId,
                        //     sensorId,
                        //     name,
                        // });

                    })
                    .catch(function (error) {
                        console.log('catch!!')
                        console.log(error)
                        // vm.displayError(`Error ${error}`);
                        return;
                    });
            },

            async getDataManual(stationId, config, sensorId, name, path) {
                var vm = this;
                console.log('Manual running.....', stationId, sensorId)
                let manualConfig = JSON.parse(config);


                console.log('CONFIG', manualConfig)
                await axios.get(
                    `${API}/manual-last-measure/${sensorId}`
                )
                    .then((data) => {
                        console.log('MANUAL DATA')
                        console.log(data.data)

                        let response = data.data;

                        let manualData = response
                        let us = this.user;

                        // ipcRenderer.send("createManualFile", {
                        //     manualData,
                        //     us,
                        //     path,
                        //     stationId,
                        //     sensorId,
                        //     name
                        // });

                    })
                    .catch(function (error) {
                        console.log('catch!!')
                        console.log(error)
                        // vm.displayError(`Error ${error}`);
                        return;
                    });
            },
            storeRain(uid, stationId, rain) {
                let obj = { uid: uid, stationId: stationId, rain };
                localStorage.setItem(`r-${stationId}`, JSON.stringify(obj));
            },
        },
    }
</script>