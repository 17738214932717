<template>
  <div class="container-fluid mt-2 mb-5">
    <vs-dialog not-center v-model="delSensor">
      <template #header>
        <h4 class="not-margin">Delete <b>sensor</b></h4>
      </template>
      <div class="con-content">
        <p>Are you sure you want to delete the sensor?</p>
      </div>
      <template #footer>
        <div class="container">
          <div class="row">
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="deleteSensor()" transparent> Yes! </vs-button>
            </div>
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="delSensor = false" danger transparent>
                Cancel
              </vs-button>
            </div>
          </div>
        </div>
      </template>
    </vs-dialog>

    <div class="row justify-content-center align-items-center">
      <div class="col-md-12 d-flex align-items-center">
        <img src="@/assets/campbell.png" width="33" class="me-2" />
        <h3 class="mb-0">Campbell Data</h3>
      </div>
      <div class="col-md-12 mt-2 mb-5">
        <div class="row">
          <div
            class="col-md-4 mt-3"
            v-for="(item, index) in farms"
            :key="index"
          >
            <div class="card main-card h-100">
              <div class="card-body">
                <h5 class="text-main-gradient">{{ item.name }}</h5>
                <!-- <span class="text-muted smallText"><span
                    class="material-icons smallText me-1">folder</span>{{item.storage_path}}</span> <br> -->

                <div
                  class="card card-station mt-1"
                  v-for="(itemStation, indexStation) in item.stations.filter(
                    (station) => station.sensor_type === 2
                  )"
                  :key="indexStation"
                >
                  <div class="card-body">
                    <!-- <span class="text-main-gradient-secondary">{{itemStation.name}}</span> <br> -->
                    <!-- <span class="material-icons smallText me-1">category</span>
                    <span class="text-muted smallText">{{item.stations[0].sensor_description}} </span> -->
                    <ul class="list-group smallText">
                      <li
                        class="list-group-item"
                        v-for="(item2, index2) in itemStation.sensors"
                        :key="index2"
                        v-if="parseInt(item2.sensor_type) == 2"
                      >
                        <template v-if="parseInt(item2.last_status) == 0">
                          <span
                            class="smallText d-flex align-items-center justify-content-left"
                          >
                            <div class="circle-gray me-1"></div>
                            {{ item2.name }}, id: {{ item2.id }}, config:
                            <a
                              class="text-link ms-2"
                              :href="extractUrl(item2.config)"
                              target="_blank"
                              >Url</a
                            >
                          </span>
                          <!-- <a class="text-link" @click="
                              turnCampbell(
                                item2.id,
                                1,
                                item2.name,
                                item2.station_id,
                                itemStation.config,
                                item.storage_path
                              )
                            ">Turn on</a> -->
                          <a class="text-link ms-2" @click="last_mesure(item2)"
                            >Last measure</a
                          >
                          <!-- <a class="text-link ms-2" @click="delete_internet(item2)">Delete</a> -->
                        </template>
                        <template v-else>
                          <span
                            class="smallText d-flex align-items-center justify-content-left"
                          >
                            <div class="circle me-1"></div>
                            {{ item2.name }}, id: {{ item2.id }}, config:
                            <a
                              class="text-link ms-2"
                              :href="extractUrl(item2.config)"
                              target="_blank"
                              >Url</a
                            >
                          </span>
                          <!-- <a class="text-link" @click="
                              turnCampbell(item2.id, 0, item2.name, item2.config,  itemStation.config, item.storage_path)
                            ">Turn off</a> -->
                          <a class="text-link ms-2" @click="last_mesure(item2)"
                            >Last measure</a
                          >
                          <!-- <a class="text-link ms-2" @click="delete_internet(item2)">Delete</a> -->
                        </template>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <transition name="fade">
            <div class="col-md-8" v-if="lastMeasure && lastMeasure.length > 0">
              <h3 class="text-main-gradient-secondary">Last measure</h3>
              <p>
                <strong>Sensor:</strong> {{ selectedSensor.name }}<br />
                <strong>Config:</strong>
                <a
                  class="text-link ms-2"
                  :href="JSON.parse(selectedSensor.config).config"
                  target="_blank"
                  >{{ JSON.parse(selectedSensor.config).config }}</a
                >
                <br />
              </p>
              <div class="row">
                <div class="col-md-6">
                  <ul class="list-group smallText">
                    <li class="list-group-item">
                      <strong class="text-main-gradient-secondary"
                        >1.-Observation_time: </strong
                      >{{ moment(lastMeasure[0].observation_time).subtract(7, 'days').format("MMM Do YYYY
                      HH:m:ss") }}
                    </li>
                    <li class="list-group-item">
                      <strong class="text-main-gradient-secondary"
                        >2.-pTemp: </strong
                      >{{ lastMeasure[0].pTemp }}
                    </li>
                    <li class="list-group-item">
                      <strong class="text-main-gradient-secondary"
                        >3.- batt_volt: </strong
                      >{{ lastMeasure[0].batt_volt }}
                    </li>
                    <li class="list-group-item">
                      <strong class="text-main-gradient-secondary"
                        >4.- airTemp_v: </strong
                      >{{ lastMeasure[0].airTemp_v }}
                    </li>
                    <li class="list-group-item">
                      <strong class="text-main-gradient-secondary"
                        >5.- vwc: </strong
                      >{{ lastMeasure[0].vwc }}
                    </li>
                    <li class="list-group-item">
                      <strong class="text-main-gradient-secondary"
                        >6.- soilTemp: </strong
                      >{{ lastMeasure[0].soilTemp }}
                    </li>
                    <li class="list-group-item">
                      <strong class="text-main-gradient-secondary"
                        >7.- permittivity: </strong
                      >{{ lastMeasure[0].permittivity }}
                    </li>
                    <li class="list-group-item">
                      <strong class="text-main-gradient-secondary"
                        >8.- conductivity: </strong
                      >{{ lastMeasure[0].conductivity }}
                    </li>
                  </ul>
                </div>

                <div class="col-md-6"></div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import axios from "axios";
import SetInterval from "set-interval";
import moment from "moment"; // Importa Moment.js

var config = require("@/config/api.json");
const API = config.api;
export default {
  name: "SoilData",
  props: {
    itemData: Object, // Declara una propiedad llamada itemData
  },
  data() {
    return {
      user: null,
      farms: [],
      active: false,
      options: [],
      lastMeasure: [],
      selectedSensor: [],
      observations: [],
      delSensor: false,
      sampleTime: 1200000,
    };
  },

  async mounted() {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      this.user = JSON.parse(storedUser);
      await this.getFarms();
      await this.getSensors();
      console.log("sensors", this.sensors);
      // this.verifyDavis();
    }
  },
  methods: {
    extractUrl(configJson) {
      try {
        const config = JSON.parse(configJson);
        return config.config;
      } catch (error) {
        console.error("Error parsing JSON:", error);
        return ""; // o puedes devolver un mensaje de error si lo prefieres
      }
    },

    async getSensors() {
      await axios.get(`${API}/api-sensors`).then((response) => {
        this.sensors = response.data;
      });
    },

    storeRain(uid, stationId, rain) {
      let obj = { uid: uid, stationId: stationId, rain };
      localStorage.setItem(`r-${stationId}`, JSON.stringify(obj));
    },

    async getDataCampbell(stationId, config, sensorId, name, path) {
      var vm = this;
      console.log("Campbell running.....", stationId, sensorId);
      let campbellConfig = JSON.parse(config);

      console.log("CONFIG", campbellConfig);
      await axios
        .post(`${API}/getCampbellData`, {
          api: campbellConfig,
        })
        .then((response) => {
          if (response.data == "error-nested-modem") {
            console.log("SMS 1 ----------------------ERROR NESTED ");
            this.notify(
              "error",
              "Error on campbell router. Please click on 'config: url' to verify modem connection"
            );
          } else {
            let responseValues = response.data.data[0].vals;
            let responseNames = response.data.head.fields;
            var newObj = [];
            for (var key = 0; key < responseValues.length; key++) {
              newObj.push({
                value: responseValues[key],
                name: responseNames[key].name,
              });
            }
            console.log(newObj);
          }

          let campbellData = newObj;

          let us = this.user;
          // ipcRenderer.send("createCampbellFile", {
          //   campbellData,
          //   us,
          //   path,
          //   stationId,
          //   sensorId,
          //   name,
          // });
        })
        .catch(function (error) {
          console.log("catch!!");
          console.log(error);
          // vm.displayError(`Error ${error}`);
          return;
        });
    },

    delete_internet(item) {
      this.selectedSensor = item;
      this.lastMeasure = [];
      this.delSensor = true;
    },

    deleteSensor() {
      axios
        .post(`${API}/delete-campbell-sensor`, {
          sensor_id: this.selectedSensor.id,
          station_id: this.selectedSensor.station_id,
        })
        .then((response) => {
          this.notify("success", "Sensor deleted successfully");
        })
        .catch((error) => {
          console.error(error.response.data.error);
          this.notify("error", error.response.data.error);
        })
        .finally(() => {
          this.delSensor = false;
          this.$emit("campbell-deleted", true);
        });
    },

    async last_mesure(item) {
      this.selectedSensor = item;
      this.lastMeasure = [];
      try {
        const response = await axios.get(
          `${API}/campbell-last-measure/${this.selectedSensor.id}`
        );
        setTimeout(() => {
          this.lastMeasure = response.data;
        }, 333);
      } catch (error) {
        console.error("Error", error);
        this.notify("error", "Failed to fetch farms");
      }
    },

    async turnCampbell(id, value, name, stationId, station_config, path) {
      try {
        const response = await axios.post(`${API}/turnCampbell`, {
          id: id,
        });

        if (parseInt(value) == 0) {
          SetInterval.clear(`campbell${id}`);
          this.notify("success", `Stopped ${name}`);
          this.$emit("campbell-turned", false);
        } else {
          SetInterval.clear(`campbell${id}`);
          console.log("BEFORE:::::::::");
          console.log(id, value, name, stationId, station_config, path);
          this.getDataCampbell(stationId, station_config, id, name, path);
          SetInterval.start(
            () => {
              this.getDataCampbell(stationId, station_config, id, name, path);
            },
            this.sampleTime,
            `campbell${id}`
          );
          this.notify("success", `Started ${name}`);
          this.$emit("campbell-turned", true);
        }
        this.getFarms();
      } catch (error) {
        console.error("Error", error);
        this.notify("error", "Failed to fetch farms");
      }
    },

    async getFarms() {
      this.farms = [];
      try {
        const response = await axios.get(
          `${API}/getFarmsByOwnerSingle/${this.user.id}/${this.itemData.id}`
        );
        this.farms = response.data;
        this.sampleTime = parseInt(this.farms[0].sampleTime * 60000);
        console.log("GET FARS: ", this.farms, "sampletime", this.sampleTime);
      } catch (error) {
        console.error("Error", error);
        this.notify("error", "Failed to fetch farms");
      }
    },
    async verifyDavis() {
      return;
      if (this.farms && this.farms.length > 0) {
        this.farms.forEach((farm) => {
          if (farm.stations && farm.stations.length > 0) {
            const soilStations = farm.stations.filter(
              (station) => station.sensor_type === 1
            );
            if (soilStations.length > 0) {
              console.log("Stations", soilStations);
              soilStations.forEach((station) => {
                if (station.sensors && station.sensors.length > 0) {
                  const activeSensors = station.sensors.filter(
                    (sensor) => sensor.last_status === 1
                  );
                  if (activeSensors.length > 0) {
                    activeSensors.forEach((element) => {
                      console.log(element);
                      SetInterval.start(
                        () => {
                          this.getDataCampbell(
                            element.config,
                            farm.storage_path,
                            element.id,
                            station.name
                          );
                        },
                        60000,
                        `soilScout${element.id}`
                      );
                      this.getDataCampbell(
                        element.config,
                        farm.storage_path,
                        element.id,
                        station.name
                      );
                    });
                  }
                }
              });
            }
          }
        });
      }
    },
  },
};
</script>
