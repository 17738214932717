import { initializeApp } from "firebase/app";

import {
    getAuth,
    updateEmail,
    updatePassword,
    sendEmailVerification,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    updateProfile,
    onAuthStateChanged,
    signOut,
} from "firebase/auth";
import {
    getStorage,
    ref,
    uploadBytesResumable,
    getDownloadURL,
} from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyB18uAUNXpRAnmv7OaLPoXK2jYKiPwyKc4",
    authDomain: "agtlink-b5cf4.firebaseapp.com",
    projectId: "agtlink-b5cf4",
    storageBucket: "agtlink-b5cf4.appspot.com",
    messagingSenderId: "763436173948",
    appId: "1:763436173948:web:e231f23ca52f2928c5d7f9",
    measurementId: "G-6F3HFQJ2EE"
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);




export { app, auth, storage };
