<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 ms-sm-auto col-lg-12">
          <div class="container-fluid">
            <div class="row" v-show="tierOn">
              <div class="col-md-12">
                <h4>
                  <span class="material-icons text-main-gradient">electrical_services</span>
                  Addons
                </h4>
                <p>Select the addons of your preference.</p>
                <div class="card main-card2 mt-3" v-for="(item, index) in addons" :key="item.id"
                  :class="{ selectCard: isSelected(index) }" @click="clickAddon(index, item)">
                  <div class="card-body">
                    <template v-if="item.dbName == '10StocusAddon'">
                      <span class="d-flex align-items-center justify-content-start"><img src="@/assets/scouts.png"
                          width="27" />
                        <p class="mb-0 ms-2">{{ item.name }}</p>
                      </span>
                    </template>
                    <template v-if="item.dbName == '10DavisAddon'">
                      <span class="d-flex align-items-center justify-content-start"><img src="@/assets/davis.png"
                          width="27" />
                        <p class="mb-0 ms-2">{{ item.name }}</p>
                      </span>
                    </template>
                    <template v-if="item.dbName == '1MonthAddon'">
                      <span class="d-flex align-items-center justify-content-start"><img src="@/assets/cloud.png"
                          width="27" />
                        <p class="mb-0 ms-2">{{ item.name }}</p>
                      </span>
                    </template>
                    <template v-if="item.dbName == '3MonthAddon'">
                      <span class="d-flex align-items-center justify-content-start"><img src="@/assets/cloud.png"
                          width="27" />
                        <p class="mb-0 ms-2">{{ item.name }}</p>
                      </span>
                    </template>
                    <template v-if="item.dbName == 'AnnualHostingAddon'">
                      <span class="d-flex align-items-center justify-content-start"><img src="@/assets/hosting.png"
                          width="27" />
                        <p class="mb-0 ms-2">{{ item.name }}</p>
                      </span>
                    </template>
                    <span class="text-muted smallText"><strong>Price:</strong> ${{ item.cost_usd }} usd</span>
                  </div>
                </div>
                <div class="mt-3">
                  <ol class="list-group list-group-numbered">
                    <li class="list-group-item d-flex justify-content-between align-items-start"
                      v-for="(item, index) in selectedAddons" :key="item.id">
                      <div class="ms-2 me-auto">
                        <div class="fw-bold">{{ item.name }}</div>
                      </div>
                      <span class="badge rounded-pill bg-light text-dark">${{ item.cost_usd }} usd</span>
                    </li>
                  </ol>
                  <hr />
                  <h5 class="text-end text-main-gradient-secondary">
                    <strong>Total:</strong> ${{ totalCostUsd }} usd
                  </h5>
                </div>
              </div>
              <div class="col-md-12 mt-5 text-center">
                <vs-button @click="continue1()" block>Continue with payment</vs-button>
              </div>
            </div>
            <div class="row" v-show="selectedOn">
              <div class="col-md-12">
                <h4>Checkout</h4>
                <p>
                  Please check that the data of the tier you have chosen is
                  correct.
                </p>
                <div class="card main-card">
                  <div class="card-body">
                    <ol class="list-group list-group-numbered">
                      <li class="list-group-item d-flex justify-content-between align-items-start"
                        v-for="(item, index) in selectedAddons" :key="item.id">
                        <div class="ms-2 me-auto">
                          <div class="fw-bold">{{ item.name }}</div>
                        </div>
                        <span class="badge rounded-pill bg-light text-dark">${{ item.cost_usd }} usd</span>
                      </li>
                    </ol>
                    <hr />
                    <h5 class="text-end text-main-gradient-secondary">
                      <strong>Total:</strong> ${{ totalCostUsd }} usd
                    </h5>
                  </div>
                </div>
              </div>
              <div class="col-md-12 mt-3">
                <p>Card Data</p>
                <div ref="cardElement"></div>
              </div>
              <div class="col-md-12 mt-5 text-center">
                <vs-button @click="processPayment()" block>Confirm Payment</vs-button>
                <a class="text-link smallText text-center" @click="backToAddons()">Return</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-12 text-center">
          <span class="nav-link smallText d-flex align-items-center justify-content-center">Powered by
          </span>
          <img class="align-items-center justify-content-center" src="@/assets/stripe.svg" alt="Stripe Logo"
            width="99" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { loadStripe } from "@stripe/stripe-js";
  import Navbar from "@/components/Navbar.vue";
  import Sidebar from "@/components/Footer.vue";
  import axios from "axios";
  var config = require("@/config/api.json");
  const API = config.api;
  import { CardElement } from "@stripe/stripe-js";
  import {
    createUserWithEmailAndPassword,
    sendEmailVerification,
    EmailAuthProvider,
    updateEmail,
    reauthenticateWithCredential,
    updatePassword,
    updateProfile,
  } from "firebase/auth";
  import { auth } from "@/fb";

  export default {
    name: "Dashaboard",
    components: {
      Navbar,
      Sidebar,
    },
    watch: {
      billed(value) {
        if (value) {
          this.billTime = "annnually";
          this.tier1 = 600;
          this.tier2 = 1200;
          this.tier2p = 1600;
        } else {
          this.billTime = "monthly";
          this.tier1 = 50;
          this.tier2 = 100;
          this.tier2p = 160;
        }
      },
    },
    computed: {
      totalCostUsd() {
        return this.selectedAddons.reduce((total, item) => {
          return total + item.cost_usd;
        }, 0);
      },
    },
    data() {
      return {
        active: false,
        billed: true,
        billTime: "monthly",
        tier1: 50,
        tier2: 100,
        tier2p: 160,
        addons: [],
        addonsOn: true,
        selectedOn: false,
        tierOn: false,
        selectedTierId: null,
        selectedTierCost: null,
        selectedTierName: null,
        selectedTierTime: null,
        cardElement: null,
        stripe: null,
        storedUser: null,
        loadTier: [],
        tierSelected: 0,
        farmName: null,
        storage_path: null,
        packageId: null,
        selectedCardIndex: [],
        selectedAddons: [],
      };
    },
    async created() {
      try {
        // this.storedUser = null
        // localStorage.removeItem('user');
        // await this.checkAuthState();
        this.storedUser = JSON.parse(localStorage.getItem("user"));
        console.log("STORED USER:::::::::::::::::::::::");
        if (this.storedUser) {
          this.storedUser = JSON.parse(localStorage.getItem("user"));
          const user = auth.currentUser;
          console.log("STORED USER::::");
          this.tierOn = true;
          console.log(this.storedUser);
          // if (parseInt(this.storedUser.addons.length) >= 1) {
          //     for (const element of this.storedUser.addons) {
          //         console.log('element', element);
          //         if (parseInt(element.status) === 1) {
          //             let tierId = element.id;
          //             this.packageId = element.id;
          //             this.active = true;
          //             this.addonsOn = false;
          //             this.selectedOn = false;
          //             // this.getTierById(element.tier, tierId);
          //             break;
          //         }
          //     }
          // }
        }
      } catch (error) {
        console.error("Error en created: ", error);
        // Manejar el error, por ejemplo, redirigir al usuario, mostrar un mensaje, etc.
      }
    },
    async mounted() {
      await this.getAddons();
      setTimeout(() => {
        this.initializeStripe();
      }, 2000);

      // ipcRenderer.on("getRoute", (event, args) => {
      //   if (!args.route[0]) {
      //     return;
      //   } else {
      //     var regex = /\\/g;
      //     var FilePath = args.route[0].replace(regex, "\\\\");
      //     this.storage_path = FilePath;
      //   }
      // });
    },
    methods: {
      isSelected(index) {
        console.log("index", index);
        return this.selectedCardIndex.includes(index);
      },

      clickAddon(index, item) {
        const selectedIndex = this.selectedCardIndex.indexOf(index);

        if (selectedIndex > -1) {
          // Si el índice ya está en selectedCardIndex, quitarlo
          this.selectedCardIndex.splice(selectedIndex, 1);
          this.selectedAddons.splice(selectedIndex, 1);
        } else {
          // Si el índice no está en selectedCardIndex, agregarlo
          this.selectedCardIndex.push(index);
          this.selectedAddons.push(item);
        }
      },
      async reloadData() {
        localStorage.removeItem("user");
        await this.checkAuthState();
        const user = auth.currentUser;
        if (user) {
          this.storedUser = JSON.parse(localStorage.getItem("user"));
          console.log(this.storedUser);
          if (parseInt(this.storedUser.addons.length) >= 1) {
            for (const element of this.storedUser.addons) {
              console.log("element", element);
              if (parseInt(element.status) === 1) {
                let tierId = element.id;
                this.packageId = element.id;
                this.active = true;
                this.tierOn = true;
                this.addonsOn = false;
                this.selectedOn = false;
                // this.getTierById(element.tier, tierId);
                break; // Salir del bucle tan pronto como se cumpla la condición
              }
            }
          }
        }
      },
      async continue1() {
        this.tierOn = false;
        this.selectedOn = true;

        return;

        if (parseInt(this.tierSelected) == 0) {
          this.notify("error", "Please select yout tier");
          return;
        }
        if (!this.vEmpty(this.farmName, "Farm Name")) {
          return;
        }

        if (this.farmName.length > 50) {
          this.notify("error", "Too many characters for a farm name");
          return;
        }

        if (!this.vEmpty(this.storage_path, "Storage Path")) {
          return;
        }

        if (this.storage_path.length > 255) {
          this.notify("error", "Storage path is too large");
          return;
        }

        console.log(this.user);

        const newFarm = {
          user_id: this.storedUser.id,
          name: this.farmName,
          storage_path: this.storage_path,
          triedId: this.tierSelected,
          packageId: this.packageId,
          features: JSON.stringify(this.loadTier.features),
        };

        this.openLoading();

        try {
          await axios.post(`${API}/register-farm-tier`, newFarm);
        } catch (error) {
          this.closeLoading();
          this.notify("error", error.response.data.error);
        } finally {
          this.notify("success", "Tier was registered successfully");
          this.closeLoading();
          setTimeout(() => {
            location.reload();
          }, 1000);
        }
      },
      handleFileUpload() {
        // ipcRenderer.send("select-dirs", true);
      },
      async initializeStripe() {
        this.stripe = await loadStripe("pk_test_k0WgApFLXk6BlSSAbKspgEJq");
        const elements = this.stripe.elements();
        this.cardElement = elements.create("card", {
          style: {
            base: {
              fontSize: "16px",
              color: "#0e7e29",
            },
          },
        });
        this.cardElement.mount(this.$refs.cardElement);
      },
      backToAddons() {
        this.selectedOn = false;
        this.tierOn = true;
      },
      selectTier(id, cost, name, time) {
        this.selectedTierId = id;
        this.selectedTierCost = cost;
        this.selectedTierName = name;
        this.selectedTierTime = time;
        this.addonsOn = false;
        this.tierOn = false;
        this.selectedOn = true;
      },
      async getAddons(item) {
        this.addons = [];
        await axios
          .get(`${API}/getAddons`)
          .then((data) => {
            let response = data.data;
            if (response.length > 0) {
              this.addons = response;
            }
          })
          .catch(function (error) {
            console.log("entra");
            console.log(error);
          });
      },
      async getTierById(id, packageId) {
        this.loadTier = [];
        await axios
          .get(`${API}/getTierbyId/${id}`)
          .then((data) => {
            let response = data.data;
            this.loadTier = response;
            this.loadTier.package_id = packageId;
            console.log("load TIER");
            console.log(this.loadTier);
          })
          .catch(function (error) {
            console.log(error);
          });
      },
      async processPayment() {
        this.openLoading();
        const { token, error } = await this.stripe.createToken(this.cardElement);
        if (error) {
          // Manejar el error (puede mostrar un mensaje de error al usuario)
          console.error(error.message);
          this.closeLoading();
        } else {
          // Enviar el token al backend
          this.sendTokenToBackend(token);
        }
      },
      async sendTokenToBackend(token) {
        try {
          const response = await axios.post(`${API}/make-payment-addon`, {
            token: token.id,
            amount: parseInt(this.totalCostUsd * 100),
            user: this.storedUser,
            addon: this.selectedAddons,
          });

          // Manejar la respuesta del backend (puede mostrar un mensaje de éxito al usuario)
          console.log(response.data);
          this.cardElement.clear();
          this.closeLoading();
          this.notify("success", "Successfull Payment");
          this.cardElement.clear();
          setTimeout(() => {
            location.reload();
            // this.reloadData();
          }, 1500);
        } catch (error) {
          this.closeLoading();
          this.cardElement.clear();
          // Manejar errores del backend (puede mostrar un mensaje de error al usuario)
          console.error(error.response.data);
        }
      },
    },
  };
</script>