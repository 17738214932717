<template>
  <div class="container-fluid mt-2 mb-5">
    <!-- Modal eliminar usuario de fb y de db -->
    <vs-dialog not-center v-model="modalDelete">
      <template #header>
        <h4 class="not-margin">Delete <b>farm</b></h4>
      </template>
      <div class="con-content">
        <p>
          ¿Are you sure you want to delete farm
          <strong>{{ selectedFarm.name }}</strong> ?
        </p>
      </div>
      <template #footer>
        <div class="container">
          <div class="row">
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="deleteFarm()" transparent> Yes! </vs-button>
            </div>
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="modalDelete = false" danger transparent>
                Cancel
              </vs-button>
            </div>
          </div>
        </div>
      </template>
    </vs-dialog>

    <!-- Modal eliminar sensor soil-->
    <vs-dialog not-center v-model="modalDeleteSensor">
      <template #header>
        <h4 class="not-margin">Delete <b>farm</b></h4>
      </template>
      <div class="con-content">
        <p>
          ¿Are you sure you want to delete
          <strong>{{ selectedSensor.name }}</strong> ?
        </p>
      </div>
      <template #footer>
        <div class="container">
          <div class="row">
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="delSensor()" transparent> Yes! </vs-button>
            </div>
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="modalDeleteSensor = false" danger transparent>
                Cancel
              </vs-button>
            </div>
          </div>
        </div>
      </template>
    </vs-dialog>

    <!-- Edición de granja -->
    <vs-dialog not-center v-model="modalEdit">
      <template #header>
        <h4 class="not-margin">Edit <b>farm</b></h4>
      </template>
      <div class="con-content">
        <div class="form-group">
          <p class="text-muted">Name</p>
          <input
            type="text"
            class="form-control mr-1"
            placeholder="Blue berry domain"
            v-model.trim="nameEdit"
            maxlength="50"
          />

          <p class="text-muted mt-3">Storage Path</p>
          <input
            type="text"
            class="form-control mr-1"
            placeholder="Select a Path"
            v-model.trim="storage_pathEdit"
            maxlength="500"
            disabled
          />
          <a
            href="#"
            class="text-link smallText me-2"
            v-on:click="handleFileUpload()"
            >Select Path</a
          >
          <p class="text-muted">
            Storage path is the place where all the text files that feed the ICC
            software will be stored.
          </p>
        </div>
      </div>
      <template #footer>
        <div class="container">
          <div class="row">
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="editFarm()" block> Save Changes </vs-button>
            </div>
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="modalEdit = false" danger block>
                Cancel
              </vs-button>
            </div>
          </div>
        </div>
      </template>
    </vs-dialog>

    <!-- Edición de granja -->
    <vs-dialog not-center v-model="modalConnect">
      <template #header>
        <h4 class="not-margin">
          <code> {{ selectedFarm.name }} domain </code>
        </h4>
      </template>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <!-- <div class="card main-card"> -->
            <!-- <div class="card-body"> -->
            <div class="form-group">
              <p class="smallText">
                Sensor type acording with your station
                <select
                  class="form-control mt-2"
                  id="sensor_type"
                  v-model="sensor_type"
                  disabled
                >
                  <option
                    :value="item.id"
                    v-for="(item, index) in sensor_types"
                    :key="index"
                  >
                    {{ item.sensor_type }}
                  </option>
                </select>
              </p>
            </div>
            <hr />
            <!-- CAMPBELL CONFIG -->
            <div class="col-md-12 mt-3">
              <div class="form-group">
                <p>Sensor name:</p>
                <p>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    v-model.trim="campBellName"
                    placeholder="Enter a name to recognize the API more quickly"
                  />
                </p>
              </div>

              <div class="form-group">
                <p>Sensor url value:</p>
                <p>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    v-model.trim="campbellValue"
                    placeholder="Url value"
                  />
                </p>
              </div>
            </div>
            <!-- CAMPBELL CONFIG -->
            <!-- </div> -->
            <!-- </div> -->
          </div>
        </div>
      </div>
      <template #footer>
        <div class="container">
          <div class="row">
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="saveCampbellSensor()" block>
                Save Changes
              </vs-button>
            </div>
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="modalConnect = false" danger block>
                Cancel
              </vs-button>
            </div>
          </div>
        </div>
      </template>
    </vs-dialog>

    <div class="row justify-content-start align-items-center">
      <div class="col-md-12">
        <h3>Campbell Config</h3>
      </div>
      <div class="col-md-12 mt-2 mb-5" v-show="campbellConfig">
        <!-- <div class="card main-card"> -->
        <!-- <div class="card-body"> -->
        <div class="form-group">
          <!-- <p class="text-muted">Name</p> -->
          <input
            type="hidden"
            class="form-control mr-1"
            placeholder="Station name or identifier"
            v-model.trim="stationName"
            maxlength="50"
          />

          <p class="text-muted mt-3">
            Select a sensor type
            <select
              class="form-control mt-2"
              id="station_sensor"
              v-model="station_sensor"
              disabled
            >
              <option value="0">Select a sensor type</option>
              <option
                :value="item.id"
                v-for="(item, index) in sensor_types"
                :key="index"
              >
                {{ item.sensor_type }}
              </option>
            </select>
          </p>

          <!-- SOIL SCOUTS CONFIG -->
          <template v-if="showCampbellForm">
            <!-- <div class="card main-card"> -->
            <!-- <div class="card-body"> -->
            <p>Url validator</p>
            <p class="text-muted">Enter your Campbell API credentials</p>

            <template v-if="!selectedAgt">
              <p class="text-muted mt-3">Enter api-url</p>
              <input
                type="text"
                class="form-control mr-1"
                placeholder="api endpoint"
                v-model.trim="apiKey"
                maxlength="500"
                @focus="clearButton"
              />

              <template v-if="campbellValidationFound">
                <p class="mt-3">
                  <strong class="text-main-gradient-secondary"
                    >Data found:</strong
                  >
                  <br />
                  <strong>-station_name:</strong>
                  {{ campbellValidationFound.station_name }} <br />
                  <strong>-table_name:</strong>
                  {{ campbellValidationFound.table_name }}<br />
                  <strong>-model:</strong>
                  {{ campbellValidationFound.model }}<br />
                  <strong>-serial_no:</strong>
                  {{ campbellValidationFound.serial_no }}<br />
                  <strong>-os_version:</strong>
                  {{ campbellValidationFound.os_version }}<br />
                  <strong>-prog_name:</strong>
                  {{ campbellValidationFound.prog_name }}<br />
                </p>
              </template>

              <vs-button class="mt-3" @click="testCampbellConnection()" block>
                Test API
              </vs-button>
            </template>
            <!-- </div> -->
            <!-- </div> -->
          </template>

          <p class="text-muted mt-3">Farm/domain</p>
          <select class="form-control" v-model="stationFarmId" disabled>
            <option
              v-for="(item, index) in allFarms"
              :key="index"
              :value="item.id"
            >
              {{ item.name }}
            </option>
          </select>

          <vs-button
            class="mt-3"
            @click="createStation()"
            v-if="showSaveCampbell"
            block
          >
            Save Configuration
          </vs-button>
        </div>
        <!-- </div> -->
        <!-- </div> -->
      </div>
    </div>
    <div class="row" v-if="!campbellConfig">
      <div
        class="col-md-12 mt-3"
        v-for="(item, index) in farms"
        v-if="item.sensorName == 'Campbell' && itemData.id == item.id"
        :key="index"
      >
        <!-- <div class="card main-card"> -->
        <!-- <div class="card-body"> -->
        <h5 class="text-main-gradient-secondary">{{ item.name }}</h5>

        <p>
          <!-- <span class="text-main-gradient-secondary">{{item.stationName}} </span> <br> -->
          <span class="material-icons smallText me-1">category</span>
          <span class="text-muted smallText">{{ item.sensorName }} </span>
          <br />
          <span class="text-muted smallText"
            ><span class="material-icons smallText me-1">folder</span
            >{{ item.storage_path }}</span
          >
          <br />
          <small class="text-muted smallText"
            ><span class="material-icons smallText me-1">calendar_month</span
            >Created at: {{ moment(item.cdt).format("MMM Do YYYY") }}</small
          >
        </p>
        <p>
          <a class="text-link smallText me-2" @click="connectSensor(item)"
            ><span class="material-icons smallText">cable</span>Connect
            Sensor</a
          >
          <!-- <a class="text-link smallText me-2" @click="editUserModal(item)"><span
                  class="material-icons smallText">edit</span>Edit</a> -->
          <!-- <a class="text-link smallText" @click="delFarmModal(item)"><span
                  class="material-icons smallText ">delete</span>Delete Farm</a> -->
          <a class="text-link smallText" @click="seeSensors(item)"
            ><span class="material-icons smallText">keyboard_arrow_down</span
            >See Sensors</a
          >
        </p>

        <ul class="list-group smallText">
          <li
            class="list-group-item"
            v-for="(item, index) in soilSensors"
            :key="index"
          >
            <strong>-{{ item.name }}</strong> ID value: {{ item.config }}
            <br />
            <!-- <a class="text-link smallText" @click="delSensorModal(item)"><span
                    class="material-icons smallText ">delete</span>Delete Sensor</a> -->
          </li>
        </ul>
        <!-- </div> -->
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import axios from "axios";
var config = require("@/config/api.json");
const API = config.api;
export default {
  name: "Davis",
  props: {
    itemData: Object, // Declara una propiedad llamada itemData
  },
  data() {
    return {
      name: null,
      storage_path: null,
      user: null,
      farms: [],
      allFarms: [],
      sensor_types: [],
      modalDelete: false,
      modalEdit: false,
      selectedFarm: [],
      modalConnect: false,
      nameEdit: null,
      storage_pathEdit: null,
      sensor_type: 2,
      showCampbellConfig: false,
      station_sensor: 2,
      showCampbellForm: true,
      stationName: "agt",
      stationFarmId: null,
      lastId: null,
      apiKey: null,
      apiSecret: null,
      selectedAgt: false,
      campBellName: null,
      campbellValue: null,
      soilSensors: [],
      selectedSensor: [],
      modalDeleteSensor: false,
      sensorParent: [],
      campbellConfig: true,
      showSaveCampbell: false,
      campbellValidationFound: null,
    };
  },
  watch: {
    station_sensor(value) {
      this.showCampbellForm = false;
      switch (value) {
        case 2: // Soil Scouts
          this.showCampbellForm = false;
          break;

        default:
          break;
      }
    },
    sensor_type(value) {
      this.showCampbellConfig = false;
      switch (value) {
        case 2: // Campbell
          this.showCampbellConfig = true;
          break;

        default:
          break;
      }
    },
  },
  mounted() {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      this.user = JSON.parse(storedUser);
      console.log("USER FRO SOI CONFIG", this.user);
      this.getFarms();
      this.getAllFarms();
      this.getSensorType();
    }

    setTimeout(() => {
      console.log("Datos del elemento seleccionado:", this.itemData);
      const sensorWithType1 = this.itemData.stations.find(
        (station) => station.sensor_type === 2
      );

      if (sensorWithType1 && sensorWithType1.id) {
        console.log(sensorWithType1.id);
        this.lastId = sensorWithType1.id;
      }
      this.getStation();
      // this.stationFarmId = this.allFarms[0].id;
      this.stationFarmId = this.itemData.id;
    }, 500);

    // ipcRenderer.on("getRoute", (event, args) => {
    //   if (!args.route[0]) {
    //     this.notify(
    //       "error",
    //       "There is no path selected, plese select it again"
    //     );
    //     return;
    //   } else {
    //     var regex = /\\/g;
    //     var FilePath = args.route[0].replace(regex, "\\\\");
    //     this.storage_path = FilePath;
    //     this.storage_pathEdit = FilePath;
    //   }
    // });
  },
  methods: {
    clearButton() {
      this.showSaveCampbell = false;
    },
    async getStation() {
      console.log("station", this.itemData);
      await axios
        .get(`${API}/getStationbyId/${this.itemData.id}/2`)
        .then((data) => {
          let response = data.data;
          console.log("STATION");
          console.log(response);
          if (response.length > 0) {
            this.campbellConfig = false;
          }
        })
        .catch(function (error) {
          console.log("entra");
          console.log(error);
        });
    },

    handleFileUpload() {
      // ipcRenderer.send("select-dirs", true);
    },

    delSensor() {
      axios
        .delete(`${API}/deleteSensor/${this.selectedSensor.id}`)
        .then((response) => {
          this.notify("success", "User deleted successfully");
        })
        .catch((error) => {
          console.error(error.response.data.error);
          this.notify("error", error.response.data.error);
        })
        .finally(() => {
          this.seeSensors(this.sensorParent);
          this.modalDeleteSensor = false;
        });
    },

    delSensorModal(item) {
      this.modalDeleteSensor = true;
      this.selectedSensor = item;
    },

    async seeSensors(item) {
      var vm = this;

      console.log("see sensors", item);
      this.sensorParent = [];
      this.sensorParent = item;
      this.soilSensors = [];

      await axios
        .get(`${API}/seeSensors/${item.stationId}/2`)
        .then(function (data) {
          let response = data.data;
          if (response.length > 0) {
            vm.soilSensors = response;
            console.log(vm.soilSensors);
          }
        })
        .catch(function (error) {
          console.log("entra");
          console.log(error);
        });
    },
    async saveCampbellSensor() {
      console.log("SELECTED ON DAVIS", this.selectedFarm);
      if (!this.selectedFarm) {
        this.notify("error", "Please select a farm/domain or station");
        return;
      }

      if (!this.campBellName) {
        this.notify("error", "There is no sensor name");
        return;
      }

      if (this.campBellName.length > 50) {
        this.notify("error", "There is no sensor value");
        return;
      }

      if (!this.campbellValue) {
        this.notify("error", "There is no sensor value/id");
        return;
      }

      let stationId = this.selectedFarm.stationId;
      if (this.lastId) {
        stationId = this.lastId;
      }

      const newSensor = {
        name: this.campBellName,
        config: this.campbellValue,
        sensor_type: 2,
        stationId: stationId,
        farmId: this.itemData.id,
      };

      this.openLoading();

      try {
        await axios.post(`${API}/createCampbellSensor`, newSensor);
        await this.updateUserInfo(this.user.parent_uid);
      } catch (error) {
        this.closeLoading();
        this.notify("error", error);
      } finally {
        this.seeSensors(this.selectedFarm);
        this.getFarms();
        this.closeLoading();
        this.soil = [];
        this.modalConnect = false;
      }
    },
    soilClearSearch() {
      this.soil = [];
      this.modalConnect = false;
      setTimeout(() => {
        this.modalConnect = true;
      }, 500);
    },
    async soilVerifySerial() {
      var vm = this;
      this.soil.sensorData = [];
      this.soil.serialOk = false;

      if (!this.vEmpty(this.soil.serialNumber, "Soil Serial Number")) {
        return;
      }
      if (this.soil.serialNumber.length > 50) {
        this.notify("error", "Storage path is too large");
        return;
      }

      await axios
        .get(
          `https://quantumsync.org:3000/api/scouts/getScoutData/${this.soil.serialNumber}`
        )
        .then(function (data) {
          let response = data.data;
          if (response == "token_not_valid") {
            this.notify(
              "The serial number does not exist or has not yet been read by the API, wait a moment and check again"
            );
            return;
          } else {
            vm.soil.serialOk = true;
            vm.soil.sensorData = data.data;
            vm.soil.apiName = vm.soil.sensorData[0].name;
            vm.soil.apiValue = vm.soil.sensorData[0].serial_number;
            console.log(vm.soil.sensorData);
            vm.soil.serialNumber = null;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    async testCampbellConnection() {
      this.campbellValidationFound = null;
      if (!this.vEmpty(this.apiKey, "api-key")) {
        return;
      }

      this.openLoading();

      try {
        axios
          .post(`${API}/testCampbellConnection`, {
            apiKey: this.apiKey,
          })
          .then((response) => {
            console.log(response);
            let data = response.data;
            console.log(data.head);
            if (data && data.head) {
              this.closeLoading();
              this.showSaveCampbell = true;
              this.campbellValidationFound = data.head.environment;
              this.notify("success", "Credentials successfully validated");
            } else {
              this.closeLoading();
              this.showSaveCampbell = false;
              this.notify("error", "Please verify your credentials api");
            }
          })
          .catch((error) => {
            this.closeLoading();
            this.showSaveCampbell = false;
            console.log(error);
            this.notify("error", "Please verify your credentials api");
          });
      } catch (error) {
        this.closeLoading();
        this.notify("error", "Please verify your credentials api");
        this.showSaveCampbell = false;
      } finally {
        // this.closeLoading();
      }
    },

    async getSensorType() {
      this.sensor_types = [];
      await axios
        .get(`${API}/sensor_type`)
        .then((response) => {
          this.sensor_types = response.data;
          console.log("sensor_types", this.sensor_types);
        })
        .catch((error) => {
          console.error("Error", error);
        });
    },

    connectSensor(item) {
      this.modalConnect = true;
      this.selectedFarm = [];
      this.selectedFarm = item;
      this.sensor_type = 2;
      console.log(item);
    },
    editUserModal(item) {
      this.modalEdit = true;
      this.selectedFarm = [];
      this.selectedFarm = item;
      this.nameEdit = this.selectedFarm.name;
      this.storage_pathEdit = this.selectedFarm.storage_path;
    },
    deleteFarm() {
      axios
        .delete(`${API}/deleteFarm/${this.selectedFarm.id}`)
        .then((response) => {
          this.notify("success", "User deleted successfully");
        })
        .catch((error) => {
          console.error(error.response.data.error);
          this.notify("error", error.response.data.error);
        })
        .finally(() => {
          this.getFarms();
          this.getAllFarms();
          this.modalDelete = false;
        });
    },
    delFarmModal(item) {
      this.modalDelete = true;
      this.selectedFarm = [];
      this.selectedFarm = item;
    },
    async editFarm() {
      if (this.user) {
        if (!this.vEmpty(this.nameEdit, "Farm Name")) {
          return;
        }

        if (this.nameEdit.length > 50) {
          this.notify("error", "Too many characters for a farm name");
          return;
        }

        if (!this.vEmpty(this.storage_pathEdit, "Email")) {
          return;
        }

        if (this.storage_pathEdit.length > 255) {
          this.notify("error", "Storage path is too large");
          return;
        }

        const newFarm = {
          id: this.selectedFarm.id,
          name: this.nameEdit,
          storage_path: this.storage_pathEdit,
        };
        this.openLoading();

        try {
          await axios.post(`${API}/editFarm`, newFarm);
          await this.updateUserInfo(this.user.parent_uid);
        } catch (error) {
          this.closeLoading();
          this.notify("error", error.response.data.error);
        } finally {
          this.nameEdit = null;
          this.storage_pathEdit = null;
          this.modalEdit = false;
          this.getFarms();
          this.closeLoading();
        }
      } else {
        this.notify("error", "There is no user loggued in.");
        return;
      }
    },
    async createStation() {
      if (this.user) {
        if (!this.vEmpty(this.stationName, "Station Name")) {
          return;
        }

        if (this.stationName.length > 50) {
          this.notify("error", "Too many characters for a station name");
          return;
        }

        if (this.station_sensor === 0) {
          this.notify("error", "Please select a sensor");
          return;
        }

        let configJson;
        switch (this.station_sensor) {
          case 2:
            if (!this.selectedAgt) {
              if (!this.vEmpty(this.apiKey, "Campbell api-url")) {
                return;
              }
              configJson = {
                apiKey: this.apiKey,
              };
            } else {
              configJson = {
                apiKey: null,
              };
            }
            break;

          default:
            break;
        }

        const newStation = {
          name: this.stationName,
          farmId: this.stationFarmId,
          sensor: this.station_sensor,
          selectedAgt: this.selectedAgt,
          config: JSON.stringify(configJson),
        };

        this.openLoading();

        let lastInsertId = null; // Inicializa la variable para almacenar el último ID insertado

        try {
          const response = await axios.post(`${API}/createStation`, newStation);
          lastInsertId = response.data.lastId;
          await this.updateUserInfo(this.user.parent_uid);
        } catch (error) {
          this.closeLoading();
          this.notify("error", error.response.data.error);
        } finally {
          this.stationName = null;
          console.log("ULTIMO ID", lastInsertId);
          this.getFarms();
          setTimeout(() => {
            this.stationFarmId = lastInsertId;
            this.selectedFarm.stationId = lastInsertId;
            this.lastId = lastInsertId;
            console.log("Asignacion", this.lastId);
            this.campbellConfig = false;
            this.closeLoading();
            this.showCampbellForm = false;
          }, 2000);
        }
      } else {
        this.notify("error", "There is no user loggued in.");
        return;
      }
    },
    async createFarm() {
      if (this.user) {
        if (!this.vEmpty(this.name, "Farm Name")) {
          return;
        }

        if (this.name.length > 50) {
          this.notify("error", "Too many characters for a farm name");
          return;
        }

        if (!this.vEmpty(this.storage_path, "Storage Path")) {
          return;
        }

        if (this.storage_path.length > 255) {
          this.notify("error", "Storage path is too large");
          return;
        }

        const newFarm = {
          user_id: this.user.id,
          name: this.name,
          storage_path: this.storage_path,
        };
        this.openLoading();

        try {
          await axios.post(`${API}/createFarm`, newFarm);
          await this.updateUserInfo(this.user.parent_uid);
        } catch (error) {
          this.closeLoading();
          this.notify("error", error.response.data.error);
        } finally {
          this.name = null;
          this.storage_path = null;
          this.notify("success", "Successfully created domain");
          this.getFarms();
          this.getAllFarms();
          this.closeLoading();
        }
      } else {
        this.notify("error", "There is no user loggued in.");
        return;
      }
    },
    async getFarms() {
      this.farms = [];
      console.log("GET ALL FARMS");
      console.log(this.user.id);
      await axios
        .get(`${API}/farms-single/${this.user.id}`)
        .then((response) => {
          console.log(response);
          this.farms = response.data;
        })
        .catch((error) => {
          console.error("Error", error);
        });
    },
    async getAllFarms() {
      this.allFarms = [];
      await axios
        .get(`${API}/allFarms/${this.user.id}`)
        .then((response) => {
          this.allFarms = response.data;
          console.log("ALL FARMS");
          console.log(this.allFarms);
        })
        .catch((error) => {
          console.error("Error", error);
        });
    },
  },
};
</script>
