<template>
  <nav :class="[
      'navbar',
      'navbar-expand-lg',
      { 'navbar-dark': !isLightMode, 'navbar-light': isLightMode },
    ]">
    <div class="container justify-content-end">
      <ul class="navbar-nav ml-auto">
        <!-- <li class="nav-item">
          <a
            class="nav-link smallText d-flex align-items-center justify-content-center"
            href="#"
          >
            <span class="material-icons smallText me-1">person</span>
            {{ displayName }}
          </a>
        </li> -->
        <li class="nav-item">
          <a class="nav-link smallText d-flex align-items-center justify-content-center" href="#">
            <div class="circle me-1"></div>
            Server Status
          </a>
        </li>
        <li class="nav-item ms-2">
          <a class="nav-link smallText d-flex align-items-center justify-content-center" href="#"
            @click="toggleLightMode">
            <span class="material-icons smallText me-1">{{
              isLightMode ? "dark_mode" : "light_mode"
              }}</span>
            {{ isLightMode ? "Dark Mode" : "Light Mode" }}
          </a>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link smallText d-flex align-items-center justify-content-center" href="#" @click="logoutUser()">
            <span class="material-icons smallText me-1">power_settings_new</span>
            Logout
          </a>
        </li> -->
      </ul>
    </div>
  </nav>
</template>

<script>
  import { mapState, mapActions } from "vuex";
  import { auth } from "@/fb";

  export default {
    data() {
      return {
        isLightMode: false,
      };
    },
    computed: {
      ...mapState(["displayName"]),
    },
    created() {
      const light = localStorage.getItem("light");
      document.body.classList.toggle("light-mode", false);
      document.body.classList.toggle("light-mode", true);

      if (light !== null) {
        // La variable existe en localStorage
        document.body.classList.toggle("light-mode", light);
        this.isLightMode = light;
      } else {
        document.body.classList.toggle("light-mode", false);
      }
      auth.onAuthStateChanged((user) => {
        if (user) {
          this.updateDisplayName(user.displayName);
          this.$emit("displayNameChanged", user.displayName);
        } else {
          this.updateDisplayName("--");
          this.$emit("displayNameChanged", "--");
        }
      });
    },
    methods: {
      ...mapActions(["updateDisplayName"]),
      toggleLightMode() {
        this.isLightMode = !this.isLightMode;
        console.log("LIGHT ", this.isLightMode);
        localStorage.setItem("light", this.isLightMode);
        document.body.classList.toggle("light-mode", this.isLightMode);
      },
      logoutUser() {
        // Implementa el método de cierre de sesión
        this.logout();
      },
    },
  };
</script>