import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);


export default new Vuex.Store({
  state: {
    displayName: '', // El estado que almacenará el displayName
    user: null,
    tierCalled: false,
    addonCalled: false,
  },
  mutations: {
    setDisplayName(state, displayName) {
      state.displayName = displayName; // La mutación para actualizar el displayName en el estado
    },
    setUser(state, user) {
      state.user = user; // La mutación para actualizar la información del usuario y sus permisos en el estado
    },
    set_tier_called(state, value) {
      console.log('MUTACION', value)
      state.tierCalled = value;
    },
    set_addon_called(state, value) {
      console.log('MUTACION ADDON', value)
      state.addonCalled = value;
    },
  },
  actions: {
    updateDisplayName({ commit }, displayName) {
      commit('setDisplayName', displayName); // La acción para actualizar el displayName en el estado
    },
    updateUser({ commit }, user) {
      commit('setUser', user); // La acción para actualizar la información del usuario y sus permisos en el estado
    },
    callTiers({ commit }) {
      console.log('LLAMADA EN STORE')
      commit('set_tier_called', true);
      setTimeout(() => {
        commit('set_tier_called', false);
      }, 2000);
    },
    callAddons({ commit }) {
      console.log('LLAMADA EN STORE ADDONS')
      commit('set_addon_called', true);
      setTimeout(() => {
        commit('set_addon_called', false);
      }, 2000);
    },
  },
});