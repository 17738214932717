<template>
  <div class="container-fluid mt-2 mb-5">
    <!-- Modal eliminar usuario de fb y de db -->
    <vs-dialog not-center v-model="modalDelete">
      <template #header>
        <h4 class="not-margin">Delete <b>farm</b></h4>
      </template>
      <div class="con-content">
        <p>
          ¿Are you sure you want to delete farm
          <strong>{{ selectedFarm.name }}</strong> ?
        </p>
      </div>
      <template #footer>
        <div class="container">
          <div class="row">
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="deleteFarm()" transparent> Yes! </vs-button>
            </div>
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="modalDelete = false" danger transparent>
                Cancel
              </vs-button>
            </div>
          </div>
        </div>
      </template>
    </vs-dialog>

    <!-- Modal eliminar sensor soil-->
    <vs-dialog not-center v-model="modalDeleteSensor">
      <template #header>
        <h4 class="not-margin">Delete <b>farm</b></h4>
      </template>
      <div class="con-content">
        <p>
          ¿Are you sure you want to delete
          <strong>{{ selectedSensor.name }}</strong> ?
        </p>
      </div>
      <template #footer>
        <div class="container">
          <div class="row">
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="delSensor()" transparent> Yes! </vs-button>
            </div>
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="modalDeleteSensor = false" danger transparent>
                Cancel
              </vs-button>
            </div>
          </div>
        </div>
      </template>
    </vs-dialog>

    <!-- Edición de granja -->
    <vs-dialog not-center v-model="modalEdit">
      <template #header>
        <h4 class="not-margin">Edit <b>farm</b></h4>
      </template>
      <div class="con-content">
        <div class="form-group">
          <p class="text-muted">Name</p>
          <input
            type="text"
            class="form-control mr-1"
            placeholder="Blue berry domain"
            v-model.trim="nameEdit"
            maxlength="50"
          />

          <p class="text-muted mt-3">Storage Path</p>
          <input
            type="text"
            class="form-control mr-1"
            placeholder="Select a Path"
            v-model.trim="storage_pathEdit"
            maxlength="500"
            disabled
          />
          <a
            href="#"
            class="text-link smallText me-2"
            v-on:click="handleFileUpload()"
            >Select Path</a
          >
          <p class="text-muted">
            Storage path is the place where all the text files that feed the ICC
            software will be stored.
          </p>
        </div>
      </div>
      <template #footer>
        <div class="container">
          <div class="row">
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="editFarm()" block> Save Changes </vs-button>
            </div>
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="modalEdit = false" danger block>
                Cancel
              </vs-button>
            </div>
          </div>
        </div>
      </template>
    </vs-dialog>

    <!-- Edición de granja -->
    <vs-dialog not-center v-model="modalConnect">
      <template #header>
        <h4 class="not-margin">
          <code> {{ selectedFarm.name }} domain </code>
        </h4>
      </template>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <!-- <div class="card main-card"> -->
            <!-- <div class="card-body"> -->
            <div class="form-group">
              <p class="smallText">
                Sensor type acording with your station
                <select
                  class="form-control mt-2"
                  id="sensor_type"
                  v-model="sensor_type"
                  disabled
                >
                  <option
                    :value="item.id"
                    v-for="(item, index) in sensor_types"
                    :key="index"
                  >
                    {{ item.sensor_type }}
                  </option>
                </select>
              </p>
            </div>
            <hr />
            <!-- CAMPBELL CONFIG -->
            <div class="col-md-12 mt-3">
              <div class="form-group">
                <GmapMap
                  :center="{ lat: 28.1988753, lng: -81.8775977 }"
                  :zoom="6"
                  map-type-id="roadmap"
                  :options="{ streetViewControl: false }"
                  style="width: 100%; height: 333px"
                  @click="mark"
                  @mousemove="navigateMap"
                >
                  <GmapMarker
                    :key="index"
                    v-for="(m, index) in markers"
                    :position="m.position"
                    :clickable="true"
                    :draggable="false"
                    @click="mark"
                    :shape="shape"
                  />
                </GmapMap>
              </div>
              <div class="form-group mt-3">
                <span>{{ lat }}</span> <br />
                <span>{{ lon }}</span>
              </div>
              <div class="form-group">
                <label for="">Sensor name:</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model.trim="internetName"
                  placeholder="Enter a name to recognize the API more quickly"
                />
              </div>
              <div class="form-group">
                <label for="">Latitude:</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model.trim="internetValueLat"
                  placeholder="0"
                  disabled
                />
              </div>
              <div class="form-group">
                <label for="">Longitude:</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model.trim="internetValueLon"
                  placeholder="0"
                  disabled
                />
              </div>
            </div>
            <!-- CAMPBELL CONFIG -->
            <!-- </div> -->
            <!-- </div> -->
          </div>
        </div>
      </div>
      <template #footer>
        <div class="container">
          <div class="row">
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="saveInternetSensor()" block>
                Save Changes
              </vs-button>
            </div>
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="modalConnect = false" danger block>
                Cancel
              </vs-button>
            </div>
          </div>
        </div>
      </template>
    </vs-dialog>

    <div class="row justify-content-start align-items-center">
      <div class="col-md-12">
        <h3>Internet Weather Config</h3>
      </div>
      <div class="col-md-12 mt-2 mb-3" v-show="internetConfig">
        <!-- <div class="card main-card"> -->
        <!-- <div class="card-body"> -->
        <div class="form-group">
          <!-- <p class="text-muted">Name</p> -->
          <input
            type="hidden"
            class="form-control mr-1"
            placeholder="Station name or identifier"
            v-model.trim="stationName"
            maxlength="50"
          />

          <p class="text-muted mt-3">
            Select a sensor type
            <select
              class="form-control mt-2"
              id="station_sensor"
              v-model="station_sensor"
              disabled
            >
              <option value="0">Select a sensor type</option>
              <option
                :value="item.id"
                v-for="(item, index) in sensor_types"
                :key="index"
              >
                {{ item.sensor_type }}
              </option>
            </select>
          </p>

          <!-- SOIL SCOUTS CONFIG -->
          <!-- <template v-if="showInternetForm">
                                <div class="card main-card">
                                    <div class="card-body">
                                        <p>Url validator</p>
                                        <p class="text-muted">
                                            Enter your Internet API credentials
                                        </p>


                                        <template v-if="!selectedAgt">
                                            <p class="text-muted mt-3">Enter api-url</p>
                                            <input type="text" class="form-control mr-1" placeholder="api endpoint"
                                                v-model.trim="apiKey" maxlength="50" @focus="clearButton" />

                                            <template v-if="internetValidationFound">
                                                <p class="mt-3">
                                                    <strong class="text-main-gradient-secondary">Data found:</strong>
                                                    <br>
                                                    <strong>-station_name:</strong>
                                                    {{internetValidationFound.station_name}} <br>
                                                    <strong>-table_name:</strong>
                                                    {{internetValidationFound.table_name}}<br>
                                                    <strong>-model:</strong> {{internetValidationFound.model}}<br>
                                                    <strong>-serial_no:</strong>
                                                    {{internetValidationFound.serial_no}}<br>
                                                    <strong>-os_version:</strong>
                                                    {{internetValidationFound.os_version}}<br>
                                                    <strong>-prog_name:</strong>
                                                    {{internetValidationFound.prog_name}}<br>

                                                </p>
                                            </template>

                                            <button type="submit" class="btn btn-primary mt-2"
                                                @click="testInternetConnection()">
                                                <span class="spanButton">Test API</span>
                                            </button>
                                        </template>
                                    </div>
                                </div>
                            </template> -->

          <p class="text-muted mt-3">Farm/domain</p>
          <select class="form-control" v-model="stationFarmId" disabled>
            <option
              v-for="(item, index) in allFarms"
              :key="index"
              :value="item.id"
            >
              {{ item.name }}
            </option>
          </select>

          <vs-button
            class="mt-3"
            @click="createStation()"
            v-if="!showSaveInternet"
            block
          >
            Save Configuration
          </vs-button>
        </div>
        <!-- </div> -->
        <!-- </div> -->
      </div>
    </div>
    <div class="row" v-if="!internetConfig">
      <div v-for="(item, index) in farms" :key="index">
        <div class="col-md-12 mt-3">
          <template v-if="item.id == itemData.id && item.sensor_type == 4">
            <!-- <div class="card main-card"> -->
            <!-- <div class="card-body"> -->
            <h5 class="text-main-gradient-secondary">{{ item.name }}</h5>

            <p>
              <!-- <span class="text-main-gradient-secondary">{{item.stationName}} </span> <br> -->
              <span class="material-icons smallText me-1">category</span>
              <span class="text-muted smallText">{{ item.sensorName }} </span>
              <br />
              <span class="text-muted smallText"
                ><span class="material-icons smallText me-1">folder</span
                >{{ item.storage_path }}</span
              >
              <br />
              <small class="text-muted smallText"
                ><span class="material-icons smallText me-1"
                  >calendar_month</span
                >Created at: {{ moment(item.cdt).format("MMM Do YYYY") }}</small
              >
            </p>
            <p>
              <a class="text-link smallText me-2" @click="connectSensor(item)"
                ><span class="material-icons smallText">cable</span>Connect
                Sensor</a
              >
              <a class="text-link smallText" @click="seeSensors(item)"
                ><span class="material-icons smallText"
                  >keyboard_arrow_down</span
                >See Sensors</a
              >
            </p>

            <ul class="list-group smallText">
              <li
                class="list-group-item"
                v-for="(item, index) in soilSensors"
                :key="index"
              >
                <strong>-{{ item.name }}</strong> lat: {{ item.latitude }} lon:
                {{ item.longitude }}
              </li>
            </ul>
            <!-- </div> -->
            <!-- </div> -->
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import axios from "axios";
var config = require("@/config/api.json");
const API = config.api;
export default {
  name: "Davis",
  props: {
    itemData: Object, // Declara una propiedad llamada itemData
  },
  data() {
    return {
      name: null,
      storage_path: null,
      user: null,
      farms: [],
      allFarms: [],
      sensor_types: [],
      modalDelete: false,
      modalEdit: false,
      selectedFarm: [],
      modalConnect: false,
      nameEdit: null,
      storage_pathEdit: null,
      sensor_type: 4,
      showInternetConfig: false,
      station_sensor: 4,
      showInternetForm: true,
      stationName: "agt",
      stationFarmId: null,
      lastId: null,
      apiKey: null,
      apiSecret: null,
      selectedAgt: false,
      internetName: null,
      internetValueLat: null,
      internetValueLon: null,
      soilSensors: [],
      selectedSensor: [],
      modalDeleteSensor: false,
      sensorParent: [],
      internetConfig: true,
      showSaveInternet: false,
      internetValidationFound: null,
      markers: [],
      markersEdit: [],
      lat: null,
      lon: null,
      shape: {
        coords: [10, 10, 10, 15, 15, 15, 15, 10],
        type: "poly",
      },
    };
  },
  watch: {
    station_sensor(value) {
      this.showInternetForm = false;
      switch (value) {
        case 4: // Soil Scouts
          this.showInternetForm = false;
          break;

        default:
          break;
      }
    },
    sensor_type(value) {
      this.showInternetConfig = false;
      switch (value) {
        case 4: // Internet
          this.showInternetConfig = true;
          break;

        default:
          break;
      }
    },
  },
  mounted() {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      this.user = JSON.parse(storedUser);
      console.log("USER FRO SOI CONFIG", this.user);
      this.getFarms();
      this.getAllFarms();
      this.getSensorType();
      this.lastId = null;
    }

    setTimeout(() => {
      console.log("Datos del elemento seleccionado:", this.itemData);
      const sensorWithType1 = this.itemData.stations.find(
        (station) => station.sensor_type === 4
      );

      if (sensorWithType1 && sensorWithType1.id) {
        console.log(sensorWithType1.id);
        this.lastId = sensorWithType1.id;
      }
      this.getStation();
      // this.stationFarmId = this.allFarms[0].id;
      this.stationFarmId = this.itemData.id;
    }, 500);

    // ipcRenderer.on("getRoute", (event, args) => {
    //     if (!args.route[0]) {
    //         this.notify(
    //             "error",
    //             "There is no path selected, plese select it again"
    //         );
    //         return;
    //     } else {
    //         var regex = /\\/g;
    //         var FilePath = args.route[0].replace(regex, "\\\\");
    //         this.storage_path = FilePath;
    //         this.storage_pathEdit = FilePath;
    //     }
    // });
  },
  methods: {
    mark(event) {
      this.markers = [];
      let lat = event.latLng.lat();
      let lng = event.latLng.lng();
      let obj = {
        position: {
          lat: lat,
          lng: lng,
        },
      };
      this.internetValueLat = lat;
      this.internetValueLon = lng;
      this.markers.push(obj);
    },
    navigateMap(e) {
      let lat = e.latLng.lat();
      let lng = e.latLng.lng();
      this.lat = lat;
      this.lon = lng;
    },
    clearButton() {
      this.showSaveInternet = false;
    },
    async getStation() {
      console.log("station", this.itemData);
      await axios
        .get(`${API}/getStationbyId/${this.itemData.id}/4`)
        .then((data) => {
          let response = data.data;
          console.log("STATION");
          console.log(response);
          if (response.length > 0) {
            this.internetConfig = false;
          }
        })
        .catch(function (error) {
          console.log("entra");
          console.log(error);
        });
    },

    handleFileUpload() {
      // ipcRenderer.send("select-dirs", true);
    },

    delSensor() {
      axios
        .delete(`${API}/deleteSensor/${this.selectedSensor.id}`)
        .then((response) => {
          this.notify("success", "User deleted successfully");
        })
        .catch((error) => {
          console.error(error.response.data.error);
          this.notify("error", error.response.data.error);
        })
        .finally(() => {
          this.seeSensors(this.sensorParent);
          this.modalDeleteSensor = false;
        });
    },

    delSensorModal(item) {
      this.modalDeleteSensor = true;
      this.selectedSensor = item;
    },

    async seeSensors(item) {
      var vm = this;

      console.log("see sensors", item);
      this.sensorParent = [];
      this.sensorParent = item;
      this.soilSensors = [];

      await axios
        .get(`${API}/seeSensors/${item.stationId}/4`)
        .then(function (data) {
          let response = data.data;
          if (response.length > 0) {
            vm.soilSensors = response;
            response.forEach((element, index) => {
              console.log(element.config);
              let config = JSON.parse(element.config);
              let config2 = JSON.parse(config.config);
              vm.soilSensors[index].latitude = config2.latitude;
              vm.soilSensors[index].longitude = config2.longitude;
            });
          }
        })
        .catch(function (error) {
          console.log("entra");
          console.log(error);
        });
    },
    async saveInternetSensor() {
      console.log("SELECTED ON INTERNET", this.selectedFarm);
      if (!this.selectedFarm) {
        this.notify("error", "Please select a farm/domain or station");
        return;
      }

      if (!this.internetName) {
        this.notify("error", "There is no sensor name");
        return;
      }

      if (this.internetName.length > 50) {
        this.notify("error", "Sensor name is too long");
        return;
      }

      if (!this.internetValueLat) {
        this.notify("error", "There is no latitude value");
        return;
      }
      if (!this.internetValueLon) {
        this.notify("error", "There is no longitude value");
        return;
      }

      let stationId = this.selectedFarm.stationId;
      if (this.lastId) {
        stationId = this.lastId;
      }

      const data = {
        latitude: this.internetValueLat,
        longitude: this.internetValueLon,
      };

      const newSensor = {
        name: this.internetName,
        config: JSON.stringify({
          latitude: this.internetValueLat,
          longitude: this.internetValueLon,
        }),
        sensor_type: 4,
        stationId: stationId,
        farmId: this.itemData.id,
      };

      this.openLoading();

      try {
        await axios.post(`${API}/createInternetSensor`, newSensor);
        await this.updateUserInfo(this.user.parent_uid);
      } catch (error) {
        this.closeLoading();
        this.notify("error", error);
      } finally {
        this.seeSensors(this.selectedFarm);
        this.getFarms();
        this.closeLoading();
        this.soil = [];
        this.modalConnect = false;
      }
    },
    soilClearSearch() {
      this.soil = [];
      this.modalConnect = false;
      setTimeout(() => {
        this.modalConnect = true;
      }, 500);
    },
    async soilVerifySerial() {
      var vm = this;
      this.soil.sensorData = [];
      this.soil.serialOk = false;

      if (!this.vEmpty(this.soil.serialNumber, "Soil Serial Number")) {
        return;
      }
      if (this.soil.serialNumber.length > 50) {
        this.notify("error", "Storage path is too large");
        return;
      }

      await axios
        .get(
          `https://quantumsync.org:3000/api/scouts/getScoutData/${this.soil.serialNumber}`
        )
        .then(function (data) {
          let response = data.data;
          if (response == "token_not_valid") {
            this.notify(
              "The serial number does not exist or has not yet been read by the API, wait a moment and check again"
            );
            return;
          } else {
            vm.soil.serialOk = true;
            vm.soil.sensorData = data.data;
            vm.soil.apiName = vm.soil.sensorData[0].name;
            vm.soil.apiValue = vm.soil.sensorData[0].serial_number;
            console.log(vm.soil.sensorData);
            vm.soil.serialNumber = null;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    async testInternetConnection() {
      this.internetValidationFound = null;
      if (!this.vEmpty(this.apiKey, "api-key")) {
        return;
      }

      this.openLoading();

      try {
        axios
          .post(`${API}/testInternetConnection`, {
            apiKey: this.apiKey,
          })
          .then((response) => {
            console.log(response);
            let data = response.data;
            console.log(data.head);
            if (data && data.head) {
              this.closeLoading();
              this.showSaveInternet = true;
              this.internetValidationFound = data.head.environment;
              this.notify("success", "Credentials successfully validated");
            } else {
              this.closeLoading();
              this.showSaveInternet = false;
              this.notify("error", "Wrong credentials");
            }
          })
          .catch((error) => {
            this.closeLoading();
            this.showSaveInternet = false;
            console.log(error);
            this.notify("error", "Wrong credentials");
          });
      } catch (error) {
        this.closeLoading();
        this.notify("error", "Wrong credentials");
        this.showSaveInternet = false;
      } finally {
        this.closeLoading();
      }
    },

    async getSensorType() {
      this.sensor_types = [];
      await axios
        .get(`${API}/sensor_type`)
        .then((response) => {
          this.sensor_types = response.data;
          console.log("sensor_types", this.sensor_types);
        })
        .catch((error) => {
          console.error("Error", error);
        });
    },

    connectSensor(item) {
      this.modalConnect = true;
      this.selectedFarm = [];
      this.selectedFarm = item;
      this.sensor_type = 4;
      console.log(item);
    },
    editUserModal(item) {
      this.modalEdit = true;
      this.selectedFarm = [];
      this.selectedFarm = item;
      this.nameEdit = this.selectedFarm.name;
      this.storage_pathEdit = this.selectedFarm.storage_path;
    },
    deleteFarm() {
      axios
        .delete(`${API}/deleteFarm/${this.selectedFarm.id}`)
        .then((response) => {
          this.notify("success", "User deleted successfully");
        })
        .catch((error) => {
          console.error(error.response.data.error);
          this.notify("error", error.response.data.error);
        })
        .finally(() => {
          this.getFarms();
          this.getAllFarms();
          this.modalDelete = false;
        });
    },
    delFarmModal(item) {
      this.modalDelete = true;
      this.selectedFarm = [];
      this.selectedFarm = item;
    },
    async editFarm() {
      if (this.user) {
        if (!this.vEmpty(this.nameEdit, "Farm Name")) {
          return;
        }

        if (this.nameEdit.length > 50) {
          this.notify("error", "Too many characters for a farm name");
          return;
        }

        if (!this.vEmpty(this.storage_pathEdit, "Email")) {
          return;
        }

        if (this.storage_pathEdit.length > 255) {
          this.notify("error", "Storage path is too large");
          return;
        }

        const newFarm = {
          id: this.selectedFarm.id,
          name: this.nameEdit,
          storage_path: this.storage_pathEdit,
        };
        this.openLoading();

        try {
          await axios.post(`${API}/editFarm`, newFarm);
          await this.updateUserInfo(this.user.parent_uid);
        } catch (error) {
          this.closeLoading();
          this.notify("error", error.response.data.error);
        } finally {
          this.nameEdit = null;
          this.storage_pathEdit = null;
          this.modalEdit = false;
          this.getFarms();
          this.closeLoading();
        }
      } else {
        this.notify("error", "There is no user loggued in.");
        return;
      }
    },
    async createStation() {
      if (this.user) {
        if (!this.vEmpty(this.stationName, "Station Name")) {
          return;
        }

        if (this.stationName.length > 50) {
          this.notify("error", "Too many characters for a station name");
          return;
        }

        if (this.station_sensor === 0) {
          this.notify("error", "Please select a sensor");
          return;
        }

        let configJson;
        // switch (this.station_sensor) {
        //     case 4:
        //         if (!this.selectedAgt) {
        //             if (!this.vEmpty(this.apiKey, "Internet api-url")) {
        //                 return;
        //             }
        //             configJson = {
        //                 apiKey: this.apiKey
        //             };
        //         } else {
        //             configJson = {
        //                 apiKey: null
        //             };
        //         }
        //         break;

        //     default:
        //         break;
        // }

        configJson = {
          apiKey: "agt",
        };

        const newStation = {
          name: this.stationName,
          farmId: this.stationFarmId,
          sensor: this.station_sensor,
          selectedAgt: this.selectedAgt,
          config: JSON.stringify(configJson),
        };

        this.openLoading();

        let lastInsertId = null; // Inicializa la variable para almacenar el último ID insertado

        try {
          const response = await axios.post(`${API}/createStation`, newStation);
          lastInsertId = response.data.lastId;
          await this.updateUserInfo(this.user.parent_uid);
        } catch (error) {
          this.closeLoading();
          this.notify("error", error.response.data.error);
        } finally {
          this.stationName = null;
          console.log("ULTIMO ID", lastInsertId);
          this.getFarms();
          setTimeout(() => {
            this.stationFarmId = lastInsertId;
            this.selectedFarm.stationId = lastInsertId;
            this.lastId = lastInsertId;
            console.log("Asignacion", this.lastId);
            this.internetConfig = false;
            this.closeLoading();
            this.showInternetForm = false;
          }, 2000);
        }
      } else {
        this.notify("error", "There is no user loggued in.");
        return;
      }
    },
    async createFarm() {
      if (this.user) {
        if (!this.vEmpty(this.name, "Farm Name")) {
          return;
        }

        if (this.name.length > 50) {
          this.notify("error", "Too many characters for a farm name");
          return;
        }

        if (!this.vEmpty(this.storage_path, "Storage Path")) {
          return;
        }

        if (this.storage_path.length > 255) {
          this.notify("error", "Storage path is too large");
          return;
        }

        const newFarm = {
          user_id: this.user.id,
          name: this.name,
          storage_path: this.storage_path,
        };
        this.openLoading();

        try {
          await axios.post(`${API}/createFarm`, newFarm);
          await this.updateUserInfo(this.user.parent_uid);
        } catch (error) {
          this.closeLoading();
          this.notify("error", error.response.data.error);
        } finally {
          this.name = null;
          this.storage_path = null;
          this.notify("success", "Successfully created domain");
          this.getFarms();
          this.getAllFarms();
          this.closeLoading();
        }
      } else {
        this.notify("error", "There is no user loggued in.");
        return;
      }
    },
    async getFarms() {
      this.farms = [];
      console.log("GET ALL FARMS");
      console.log(this.user.id);
      console.log(this.itemData);
      await axios
        .get(`${API}/farms-single/${this.user.id}`)
        .then((response) => {
          console.log(response);
          this.farms = response.data;
        })
        .catch((error) => {
          console.error("Error", error);
        });
    },
    async getAllFarms() {
      this.allFarms = [];
      await axios
        .get(`${API}/allFarms/${this.user.id}`)
        .then((response) => {
          this.allFarms = response.data;
          console.log("ALL FARMS");
          console.log(this.allFarms);
        })
        .catch((error) => {
          console.error("Error", error);
        });
    },
  },
};
</script>
