<template>
    <div class="container-fluid mt-5">
      <div class="row justify-content-center align-items-center">
        <div class="col-md-12 col-sm-12 col-lg-12 mt-5">
          <div class="card main-card">
            <div class="card-body">
              <h1>Logs</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
    export default {
      name: 'Logs',
    }
  </script>