<template>
  <div class="container-fluid mt-2 mb-5">

    <!-- Modal eliminar usuario de fb y de db -->
    <vs-dialog not-center v-model="modalDelete">
      <template #header>
        <h4 class="not-margin">
          Delete <b>farm</b>
        </h4>
      </template>
      <div class="con-content">
        <p>
          ¿Are you sure you want to delete farm <strong>{{selectedFarm.name}}</strong> ?
        </p>
      </div>
      <template #footer>
        <div class="container">
          <div class="row">
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="deleteFarm()" transparent>
                Yes!
              </vs-button>
            </div>
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="modalDelete=false" danger transparent>
                Cancel
              </vs-button>
            </div>
          </div>
        </div>
      </template>
    </vs-dialog>

    <!-- Modal eliminar sensor soil-->
    <vs-dialog not-center v-model="modalDeleteSensor">
      <template #header>
        <h4 class="not-margin">
          Delete <b>farm</b>
        </h4>
      </template>
      <div class="con-content">
        <p>
          ¿Are you sure you want to delete <strong>{{selectedSensor.name}}</strong> ?
        </p>
      </div>
      <template #footer>
        <div class="container">
          <div class="row">
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="delSensor()" transparent>
                Yes!
              </vs-button>
            </div>
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="modalDeleteSensor=false" danger transparent>
                Cancel
              </vs-button>
            </div>
          </div>
        </div>
      </template>
    </vs-dialog>

    <!-- Edición de granja -->
    <vs-dialog not-center v-model="modalEdit">
      <template #header>
        <h4 class="not-margin">
          Edit <b>farm</b>
        </h4>
      </template>
      <div class="con-content">
        <div class="form-group">
          <p class="text-muted">Name</p>
          <input type="text" class="form-control mr-1" placeholder="Blue berry domain" v-model.trim="nameEdit"
            maxlength="50" />

          <p class="text-muted mt-3">Storage Path</p>
          <input type="text" class="form-control mr-1" placeholder="Select a Path" v-model.trim="storage_pathEdit"
            maxlength="500" disabled />
          <a href="#" class="text-link smallText me-2" v-on:click="handleFileUpload()">Select Path</a>
          <p class="text-muted">Storage path is the place where all the text files that feed the ICC
            software will be stored.</p>
        </div>
      </div>
      <template #footer>
        <div class="container">
          <div class="row">
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="editFarm()" transparent>
                Save Changes
              </vs-button>
            </div>
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="modalEdit=false" danger transparent>
                Cancel
              </vs-button>
            </div>
          </div>
        </div>
      </template>
    </vs-dialog>


    <!-- Edición de granja -->
    <vs-dialog not-center v-model="modalConnect">
      <template #header>
        <h4 class="not-margin">
          <code>
            Working on {{selectedFarm.name}}/{{selectedFarm.stationName}} station
          </code>
        </h4>
      </template>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <p class="smallText">
                Sensor type acording with your station
                <select class="form-control mt-2" id="sensor_type" v-model="sensor_type" disabled>
                  <option :value="item.id" v-for="(item, index) in sensor_types" :key="index">{{item.sensor_type}}
                  </option>
                </select>
              </p>
            </div>
            <hr>
          </div>
          <!-- SOIL SOCUTS CONFIG -->
          <div class="col-md-12 mt-3" v-if="showSoilConfig">
            <div class="form-group">
              <label for="">Enter the serial number:</label>
              <input type="text" class="form-control form-control-sm" v-model.trim="soil.serialNumber"
                placeholder="Enter a name to recognize the API more quickly">
            </div>
            <div class="form-group">
              <vs-button block @click="soilVerifySerial()">Veritfy</vs-button>
            </div>
          </div>
          <div class="col-md-12 mt-3" v-if="soil.serialOk">
            <h5>Sensor found!</h5>
            <p>
              <strong>ID:</strong> {{soil.sensorData[0].id}} <br>
              <strong>Name:</strong> {{soil.sensorData[0].name}} <br>
              <strong>Device Type:</strong> {{soil.sensorData[0].device_type}} <br>
              <strong>Site ID:</strong> {{soil.sensorData[0].site}} <br>
              <strong>Device Status:</strong> {{soil.sensorData[0].device_status}} <br>
              <strong>Has Battery:</strong> {{soil.sensorData[0].has_battery}} <br>
            </p>
            <vs-button block transparent @click='soilClearSearch()'>Clear search</vs-button>
            <div class="form-group">
              <label for="">Api name</label>
              <input type="text" class="form-control form-control-sm" v-model.trim="soil.apiName"
                placeholder="Enter the soil scout serial number">
            </div>
            <div class="form-group">
              <label for="">Api Value</label>
              <input type="text" class="form-control form-control-sm" v-model.trim="soil.apiValue"
                placeholder="Http URL">
            </div>
            <hr />
          </div>
          <!-- SOIL SOCUTS CONFIG -->
        </div>
      </div>
      <template #footer>
        <div class="container">
          <div class="row">
            <div class="col-6 d-flex align-items-center justify-content-center" v-if="soil.serialOk">
              <vs-button @click="saveSoilConfig()" transparent>
                Save Changes
              </vs-button>
            </div>
            <div class="col-6 d-flex align-items-center justify-content-center" v-if="soil.serialOk">
              <vs-button @click="modalConnect=false" danger transparent>
                Cancel
              </vs-button>
            </div>
          </div>
        </div>
      </template>
    </vs-dialog>

    <div class="row justify-content-start align-items-center">
      <div class="col-md-12 ">
        <h3>Farms</h3>
      </div>
      <div class="col-md-12 mt-2 mb-5">
        <div class="accordion accordion-flush" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingOne">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                1. Create Farm
              </button>
            </h2>
            <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">

                <div class="card main-card">
                  <div class="card-body">
                    <div class="form-group">
                      <p class="text-muted">Name</p>
                      <input type="text" class="form-control mr-1" placeholder="Blue berry domain" v-model.trim="name"
                        maxlength="50" />

                      <p class="text-muted mt-3">Storage Path</p>
                      <input type="text" class="form-control mr-1" placeholder="Select a Path"
                        v-model.trim="storage_path" maxlength="500" disabled />
                      <a href="#" class="text-link smallText me-2" v-on:click="handleFileUpload()">Select Path</a>
                      <p class="text-muted">Storage path is the place where all the text files that feed the ICC
                        software will be stored.</p>

                      <button type="submit" class="btn btn-primary  mt-2" @click="createFarm()"><span
                          class="spanButton">Save</span></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingTree">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTree" aria-expanded="false" aria-controls="flush-collapseTree">
                2. Create Station
              </button>
            </h2>
            <div id="flush-collapseTree" class="accordion-collapse collapse" aria-labelledby="flush-headingTree"
              data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">

                <div class="card main-card">
                  <div class="card-body">
                    <div class="form-group">
                      <p class="text-muted">Name</p>
                      <input type="text" class="form-control mr-1" placeholder="Station name or identifier"
                        v-model.trim="stationName" maxlength="50" />

                      <p class="text-muted mt-3">
                        Select a sensor type
                        <select class="form-control mt-2" id="station_sensor" v-model="station_sensor">
                          <option value="0" selected>Select a sensor type</option>
                          <option :value="item.id" v-for="(item, index) in sensor_types" :key="index">
                            {{item.sensor_type}}</option>
                        </select>
                      </p>


                      <!-- SOIL SCOUTS CONFIG -->
                      <template v-if="showSoilForm">
                        <div class="card main-card">
                          <div class="card-body">
                            <p>Soil Scouts Config</p>
                            <p class="text-muted">Enter your soil scouts API account or enable AgTronix Account.</p>

                            <div class="col-md-4">
                              <vs-switch color="#214455" v-model="selectedAgt" label="AgTronix Soil Scouts API">
                                <template #off>
                                  Disabled AgTronix
                                </template>
                                <template #on>
                                  Enabled AgTronix
                                </template>
                              </vs-switch>
                            </div>

                            <template v-if="!selectedAgt">
                              <p class="text-muted mt-3">Username</p>
                              <input type="text" class="form-control mr-1" placeholder="Username"
                                v-model.trim="configSoilUsername" maxlength="50" />

                              <p class="text-muted mt-3">Password</p>
                              <input type="password" class="form-control mr-1" placeholder="Password"
                                v-model.trim="configSoilPassword" maxlength="50" />

                              <button type="submit" class="btn btn-primary  mt-2" @click="testSoilConnection()"><span
                                  class="spanButton">Test API</span></button>
                            </template>
                          </div>
                        </div>
                      </template>

                      <p class="text-muted mt-3">Connect farm</p>
                      <select class="form-control" v-model="stationFarmId">
                        <option v-for="(item, index) in allFarms" :key="index" :value="item.id">{{item.name}}</option>
                      </select>

                      <button type="submit" class="btn btn-primary  mt-2" @click="createStation()"><span
                          class="spanButton">Save Station</span></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingTwo">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                3. Connect Sensor
              </button>
            </h2>
            <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo"
              data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">
                <div class="row">
                  <div class="col-md-12 mt-3" v-for="(item, index) in farms" :key="index">
                    <div class="card main-card">
                      <div class="card-body">

                        <h5 class="text-main-gradient">{{item.name}}</h5>

                        <p>
                          <span class="text-main-gradient-secondary">{{item.stationName}} </span> <br>
                          <span class="material-icons smallText me-1">category</span>
                          <span class="text-muted smallText">{{item.sensorName}} </span>
                          <br>
                          <span class="text-muted smallText"><span
                              class="material-icons smallText me-1">folder</span>{{item.storage_path}}</span> <br>
                          <small class="text-muted smallText"><span
                              class="material-icons smallText me-1">calendar_month</span>Created at:
                            {{moment(item.cdt).format("MMM Do YYYY")}}</small>
                        </p>
                        <p>
                          <a class="text-link smallText me-2" @click="connectSensor(item)"><span
                              class="material-icons smallText">cable</span>Connect Sensor</a>
                          <a class="text-link smallText me-2" @click="editUserModal(item)"><span
                              class="material-icons smallText">edit</span>Edit</a>
                          <a class="text-link smallText" @click="delFarmModal(item)"><span
                              class="material-icons smallText ">delete</span>DeleteFarm</a>
                          <a class="text-link smallText" @click="seeSensors(item)"><span
                              class="material-icons smallText ">keyboard_arrow_down</span>See Sensors</a>
                        </p>

                        <ul class="list-group smallText">
                          <li class="list-group-item" v-for="(item, index) in soilSensors" :key="index">
                            <strong>-{{item.name}}</strong> config: {{item.config}} <br>
                            <a class="text-link smallText" @click="delSensorModal(item)"><span
                                class="material-icons smallText ">delete</span>Delete Sensor</a>
                          </li>
                        </ul>

                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapActions } from 'vuex';
  import axios from 'axios';
  var config = require("@/config/api.json");
  const API = config.api;
  export default {
    name: 'Farms',
    data() {
      return {
        name: null,
        storage_path: null,
        user: null,
        farms: [],
        allFarms: [],
        sensor_types: [],
        modalDelete: false,
        modalEdit: false,
        selectedFarm: [],
        modalConnect: false,
        nameEdit: null,
        storage_pathEdit: null,
        sensor_type: 1,
        showSoilConfig: false,
        station_sensor: 0,
        showSoilForm: false,
        stationName: null,
        stationFarmId: 1,
        configSoilUsername: null,
        configSoilPassword: null,
        selectedAgt: true,
        soil: {
          sensorData: [],
          serialOk: false,
          serialNumber: null,
          apiName: null,
          apiValue: null
        },
        soilSensors: [],
        selectedSensor: [],
        modalDeleteSensor: false,
        sensorParent: []
      }
    },
    watch: {
      station_sensor(value) {
        this.showSoilForm = false;
        switch (value) {
          case 3: // Soil Scouts
            this.showSoilForm = true;
            break;

          default:
            break;
        }
      },
      sensor_type(value) {
        this.showSoilConfig = false;
        switch (value) {
          case 3: // Soil Scouts
            this.showSoilConfig = true;
            break;

          default:
            break;
        }
      }
    },
    mounted() {
      const storedUser = localStorage.getItem('user');
      if (storedUser) {
        this.user = JSON.parse(storedUser);
        this.getFarms();
        this.getAllFarms();
        this.getSensorType();
      }

      // ipcRenderer.on("getRoute", (event, args) => {
      //   if (!args.route[0]) {
      //     this.notify('error', "There is no path selected, plese select it again");
      //     return;
      //   } else {
      //     var regex = /\\/g;
      //     var FilePath = args.route[0].replace(regex, "\\\\");
      //     this.storage_path = FilePath;
      //     this.storage_pathEdit = FilePath;
      //   }
      // });
    },
    methods: {

      handleFileUpload() {
        // ipcRenderer.send("select-dirs", true);
      },

      delSensor() {
        axios
          .delete(`${API}/deleteSensor/${this.selectedSensor.id}`)
          .then(response => {
            this.notify('success', 'User deleted successfully');
          })
          .catch(error => {
            console.error(error.response.data.error);
            this.notify('error', error.response.data.error);
          })
          .finally(() => {
            this.seeSensors(this.sensorParent);
            this.modalDeleteSensor = false;
          });
      },

      delSensorModal(item) {
        this.modalDeleteSensor = true
        this.selectedSensor = item
      },

      async seeSensors(item) {

        var vm = this;

        this.sensorParent = [];
        this.sensorParent = item;
        this.soilSensors = [];

        await axios.get(`${API}/seeSensors/${item.stationId}/${item.sensor_type}`)
          .then(function (data) {
            let response = data.data
            if (response.length > 0) {
              vm.soilSensors = response
              console.log(vm.soilSensors)
            }
          })
          .catch(function (error) {
            console.log('entra')
            console.log(error);
          });

      },
      async saveSoilConfig() {

        console.log(this.selectedFarm)
        console.log(this.soil)
        if (!this.selectedFarm) {
          this.notify('error', 'Please select a farm/domain or station');
          return;
        }
        if (!this.soil) {
          this.notify('error', 'There is no Soil Scouts Information');
          return;
        }

        const newSensor = {
          name: this.soil.apiName,
          config: this.soil.apiValue,
          sensor_type: this.selectedFarm.sensor_type,
          stationId: this.selectedFarm.stationId
        };

        this.openLoading();

        try {
          await axios.post(`${API}/createSoilSensor`, newSensor);
          await this.updateUserInfo(this.user.parent_uid);
        } catch (error) {
          this.closeLoading();
          this.notify('error', error);
        } finally {
          this.seeSensors(this.selectedFarm);
          this.getFarms();
          this.closeLoading();
          this.soil = [];
          this.modalConnect = false
        }



      },
      soilClearSearch() {
        this.soil = [];
        this.modalConnect = false
        setTimeout(() => {
          this.modalConnect = true
        }, 500);
      },
      async soilVerifySerial() {
        var vm = this;
        this.soil.sensorData = []
        this.soil.serialOk = false

        if (!this.vEmpty(this.soil.serialNumber, 'Soil Serial Number')) {
          return;
        }
        if (this.soil.serialNumber.length > 50) {
          this.notify('error', 'Storage path is too large');
          return;
        }


        await axios.get(`https://quantumsync.org:3000/api/scouts/getScoutData/${this.soil.serialNumber}`)
          .then(function (data) {
            let response = data.data
            if (response == 'token_not_valid') {
              this.notify("The serial number does not exist or has not yet been read by the API, wait a moment and check again")
              return;
            } else {
              vm.soil.serialOk = true
              vm.soil.sensorData = data.data
              vm.soil.apiName = vm.soil.sensorData[0].name
              vm.soil.apiValue = vm.soil.sensorData[0].serial_number
              console.log(vm.soil.sensorData);
              vm.soil.serialNumber = null
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      },

      async testSoilConnection() {
        if (!this.vEmpty(this.configSoilUsername, 'Soil Scouts Username')) {
          return;
        }
        if (!this.vEmpty(this.configSoilPassword, 'Soil Scouts Password')) {
          return;
        }
        this.openLoading();

        try {
          axios.post('https://soilscouts.fi/api/v1/auth/login/', { username: 'AgTronix', password: 'W@tergirl1' })
            .then(function (response) {
              const tokens = {
                refresh_token: response.data.refresh, access_token: response.data.access
              };
              this.closeLoading();
              console.log(response.data)
              console.log('Los tokens se han guardado en el archivo tokens.json');
            })
            .catch(function (error) {
              this.closeLoading();
              console.log(error);
            });
        } catch (error) {
          this.closeLoading();
          this.notify('error', error.response.data.error);
        } finally {
          this.closeLoading();
        }

      },

      async getSensorType() {
        this.sensor_types = [];
        await axios.get(`${API}/sensor_type`)
          .then(response => {
            this.sensor_types = response.data;
          })
          .catch(error => {
            console.error('Error', error);
          });
      },

      connectSensor(item) {
        this.modalConnect = true;
        this.selectedFarm = [];
        this.selectedFarm = item;
        this.sensor_type = item.sensor_type;
        console.log(item)
      },
      editUserModal(item) {
        this.modalEdit = true;
        this.selectedFarm = [];
        this.selectedFarm = item;
        this.nameEdit = this.selectedFarm.name;
        this.storage_pathEdit = this.selectedFarm.storage_path;
      },
      deleteFarm() {
        axios
          .delete(`${API}/deleteFarm/${this.selectedFarm.id}`)
          .then(response => {
            this.notify('success', 'User deleted successfully');
          })
          .catch(error => {
            console.error(error.response.data.error);
            this.notify('error', error.response.data.error);
          })
          .finally(() => {
            this.getFarms();
            this.getAllFarms();
            this.modalDelete = false;
          });
      },
      delFarmModal(item) {
        this.modalDelete = true;
        this.selectedFarm = [];
        this.selectedFarm = item;
      },
      async editFarm() {
        if (this.user) {
          if (!this.vEmpty(this.nameEdit, 'Farm Name')) {
            return;
          }

          if (this.nameEdit.length > 50) {
            this.notify('error', 'Too many characters for a farm name');
            return;
          }

          if (!this.vEmpty(this.storage_pathEdit, 'Email')) {
            return;
          }

          if (this.storage_pathEdit.length > 255) {
            this.notify('error', 'Storage path is too large');
            return;
          }

          const newFarm = {
            id: this.selectedFarm.id,
            name: this.nameEdit,
            storage_path: this.storage_pathEdit
          };
          this.openLoading();

          try {
            await axios.post(`${API}/editFarm`, newFarm);
            await this.updateUserInfo(this.user.parent_uid);
          } catch (error) {
            this.closeLoading();
            this.notify('error', error.response.data.error);
          } finally {
            this.nameEdit = null;
            this.storage_pathEdit = null;
            this.modalEdit = false;
            this.getFarms();
            this.closeLoading();
          }
        } else {
          this.notify('error', 'There is no user loggued in.');
          return;
        }
      },
      async createStation() {

        if (this.user) {
          if (!this.vEmpty(this.stationName, 'Station Name')) {
            return;
          }

          if (this.stationName.length > 50) {
            this.notify('error', 'Too many characters for a station name');
            return;
          }

          if (this.station_sensor === 0) {
            this.notify('error', 'Please select a sensor');
            return;
          }

          let configJson;
          switch (this.station_sensor) {
            case 1:
              config = "test";
              break;
            case 2:
              config = "test";
              break;
            case 3:
              if (!this.selectedAgt) {
                if (!this.vEmpty(this.configSoilUsername, 'Soil Socuts Username')) {
                  return;
                }
                if (!this.vEmpty(this.configSoilPassword, 'Soil Socuts Password')) {
                  return;
                }
                configJson = {
                  username: this.configSoilUsername,
                  password: this.configSoilPassword,
                };
              } else {
                configJson = {
                  username: null,
                  password: null,
                };
              }
              break;
            case 4:
              config = "test";
              break;

            default:
              break;
          }


          const newStation = {
            name: this.stationName,
            farmId: this.stationFarmId,
            sensor: this.station_sensor,
            selectedAgt: this.selectedAgt,
            config: JSON.stringify(configJson)
          };

          this.openLoading();

          try {
            await axios.post(`${API}/createStation`, newStation);
            await this.updateUserInfo(this.user.parent_uid);
          } catch (error) {
            this.closeLoading();
            this.notify('error', error.response.data.error);
          } finally {
            this.stationName = null;
            this.stationFarmId = 1;
            this.getFarms();
            setTimeout(() => {
              this.closeLoading();
            }, 2000);
          }
        } else {
          this.notify('error', 'There is no user loggued in.');
          return;
        }
      },
      async createFarm() {
        if (this.user) {
          if (!this.vEmpty(this.name, 'Farm Name')) {
            return;
          }

          if (this.name.length > 50) {
            this.notify('error', 'Too many characters for a farm name');
            return;
          }

          if (!this.vEmpty(this.storage_path, 'Storage Path')) {
            return;
          }

          if (this.storage_path.length > 255) {
            this.notify('error', 'Storage path is too large');
            return;
          }

          const newFarm = {
            user_id: this.user.id,
            name: this.name,
            storage_path: this.storage_path,
          };
          this.openLoading();

          try {
            await axios.post(`${API}/createFarm`, newFarm);
            await this.updateUserInfo(this.user.parent_uid);
          } catch (error) {
            this.closeLoading();
            this.notify('error', error.response.data.error);
          } finally {
            this.name = null;
            this.storage_path = null;
            this.notify('success', 'Successfully created domain');
            this.getFarms();
            this.getAllFarms();
            this.closeLoading();
          }
        } else {
          this.notify('error', 'There is no user loggued in.');
          return;
        }
      },
      async getFarms() {
        this.farms = [];
        console.log(this.user.id)
        await axios.get(`${API}/farms/${this.user.id}`)
          .then(response => {
            this.farms = response.data;
          })
          .catch(error => {
            console.error('Error', error);
          });
      },
      async getAllFarms() {
        this.allFarms = [];
        await axios.get(`${API}/allFarms/${this.user.id}`)
          .then(response => {
            this.allFarms = response.data;
          })
          .catch(error => {
            console.error('Error', error);
          });
      },
    },
  }
</script>