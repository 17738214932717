import Vue from 'vue';
import VueRouter from 'vue-router';
import Index from '../views/Index.vue';
import Logs from '../views/Logs.vue';
import Home from '../views/Home.vue';
import Farms from '../views/Farms.vue';
import Users from '../views/Users.vue';
import Settings from '../views/Settings.vue';

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register.vue')
  },
  {
    path: '/forgot',
    name: 'Forgot',
    component: () => import('../views/Forgot.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue'),
    children: [
      {
        path: '/home',
        name: 'Home',
        component: Home
      },
      {
        path: '/logs',
        name: 'Logs',
        component: Logs
      },
      {
        path: '/farms',
        name: 'Farms',
        component: Farms
      },
      {
        path: '/users',
        name: 'Users',
        component: Users
      },
      {
        path: '/settings',
        name: 'Settings',
        component: Settings
      },
    ]
  },
  {
    path: '*',
    redirect: '/dashboard'
  }
];

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'hash',
  routes
});

export default router;