<template>
  <div class="container mt-5 mb-5">
    <div class="row justify-content-center align-items-center">
      <div class="col-md-6 col-sm-12 col-lg-4">
        <div class="card main-card">
          <div class="card-body">
            <div class="text-center">
              <img src="@/assets/logo.png" width="133">
            </div>
            <h5 class="text-center">Login</h5>
            <div class="mb-3">
              <label for="username" class="form-label text-start">Username</label>
              <input type="text" class="form-control" placeholder="user@agtl.ink" id="username" v-model="username"
                required>
            </div>
            <div class="mb-3">
              <label for="password" class="form-label text-start">Password</label>
              <input type="password" class="form-control" placeholder="******" id="password" v-model="password"
                @keyup.enter="login" required>
            </div>
            <vs-button class="mt-3" @click="login" block>
              Login
            </vs-button>
            <hr>
            <p class="text-center smallText mt-3">
              <router-link to="/forgot">Forgot password?</router-link>
            </p>
            <p class="text-center smallText mt-3">
              Don't you have an account? <router-link class="text-link" to="/register">Sign up</router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { auth } from '../fb';
  import { signInWithEmailAndPassword } from "firebase/auth";
  import axios from 'axios';

  export default {
    name: 'Index',
    data() {
      return {
        username: null,
        password: null
      };
    },
    async created() {
      const light = localStorage.getItem('light');
      console.log('PRIMER LIGHT', light)

      if (light !== null) {
        // La variable existe en localStorage
        console.log('La variable existe en localStorage');
        document.body.classList.toggle('light-mode', light);
      } else {
        document.body.classList.toggle('light-mode', false);
      }

      try {
        await this.checkAuthState();
      } catch (error) {
        console.error("Error en mounted: ", error);
        // Manejar el error, por ejemplo, redirigir al usuario, mostrar un mensaje, etc.
      }
    },
    methods: {
      login() {
        if (this.username.trim() === '') {
          this.notify('error', 'Error: Username field is empty');
          return;
        }
        if (!this.vEmail(this.username)) {
          this.notify('error', 'Error: Invalid email');
          return;
        }

        if (this.password.trim() === '') {
          this.notify('error', 'Error: Password field is empty');
          return;
        }
        this.openLoading();
        signInWithEmailAndPassword(auth, this.username, this.password)
          .then((userCredential) => {
            console.log(userCredential);
            const targetRoute = '/home';
            if (this.$route.path !== targetRoute) {
              new Promise(resolve => setTimeout(resolve, 1000));
              this.$router.push({ name: 'Home' });
            }
          })
          .catch((error) => {
            this.notify('error', `Error: ${error.code}`);
            this.username = null;
            this.password = null;
          })
          .finally(() => {
            console.log('FINAL')
            setTimeout(() => {
              this.closeLoading();

            }, 1000);
          });
      }
    }
  }
</script>